import dayjs from "dayjs";

export const formatPayloadCreateTask = (formData: any, questionValue: any) => {
  const taskSubmissionDetail = (questionValue ?? []).map((item: any) => {
    return { id: item.id, value: item.value };
  });

  return {
    jobTitleId: formData.jobTitleId ?? "",
    startTime: formData.starDate ?? "",
    endTime: formData.endDate ?? "",
    taskTemplateId: formData.taskName ?? "",
    startSubmission: formData.startSubmission ?? "",
    endSubmission: formData.endSubmission ?? "",
    systemSlowSeconds: formData.systemSlowSeconds,
    taskSubmissionDetailDtos: taskSubmissionDetail,
    taskSubmissionTime: dayjs().format(),
    outcome: formData.outcome,
    outcomeComment: formData.outcomeComment
  };
};
