import * as XLSX from "xlsx";

export const downloadExcelTemplate = () => {
  const userArr = [
    {
      userName: "john@gmail.com",
      userFullName: "John Hard",
      jobTitle: "developer",
    },
  ];

  const Heading = [["User Name", "User Full Name", "Job Title"]];

  //Had to create a new workbook and then add the header
  const wb = XLSX.utils.book_new();
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, Heading);

  //Starting in the second row to avoid overriding and skipping headers
  XLSX.utils.sheet_add_json(ws, userArr, { origin: "A2", skipHeader: true });

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, "import_users_template.xlsx");
};
