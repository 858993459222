import { FC, useEffect, useState } from "react";
import _ from "lodash";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { JobsSearch } from "./JobsSearch";
import { JobsTable } from "./JobsTable";
import { useSetJobsState } from "../hooks/useSetJobsState";
import { fetchGetAllCompanies } from "../../organization-management/miscellaneous/fetchGetAllCompanies";
import { fetchQueryJobTitle } from "../miscellaneous/fetchQueryJobTitle";
import { useJobsValue } from "../hooks/useJobsValue";
import { usePaginationTable } from "../../common/hooks/usePaginationTable";

export const JobsManagement: FC = () => {
  const setJobs = useSetJobsState();
  const jobsValue = useJobsValue();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm();

  const {
    page,
    rowsPerPage,
    emptyRows,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage
  } = usePaginationTable(jobsValue);

  const [jobsSearch, setJobsSearch] = useState({
    companyId: "",
    title: "",
  });
  const [companiesName, setCompaniesName] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const mutationGetCompanies = useMutation(fetchGetAllCompanies, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyName = _.map(res.data.data, (item) => {
          return { name: item.companyName, value: item.id };
        });
        setCompaniesName(companyName as any);
      }
    },
    onError: () => {},
  });

  const mutateQueryJobs = useMutation(fetchQueryJobTitle, {
    onSuccess: (res) => {
      setJobs(res.data.data);
      setTotalRecords(res.data.totalRecords);
    },
    onError: () => {},
  });

  useEffect(() => {
    mutationGetCompanies.mutate();
  }, []);

  useEffect(() => {
    const payload = {
      companyId: jobsSearch.companyId,
      title: jobsSearch.title,
      pageNumber: page,
      pageSize: rowsPerPage
    };
    mutateQueryJobs.mutate(payload);
  }, [JSON.stringify(jobsSearch), page, rowsPerPage]);

  return (
    <>
      <JobsSearch
        control={control}
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
        reset={reset}
        setJobsSearch={setJobsSearch}
        companies={companiesName}
        setPage={setPage}
      />
      <JobsTable
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalRecords={totalRecords}
      />
    </>
  );
};
