import { ButtonA } from "../../common/components/ButtonA";
import { userManager } from "../../getOidc";

export const SignIn = () => {

  const handleProcssesSignIn = () => {
    userManager
      .createSigninRequest()
      .then((result) => {
        (window as any).location = result.url;
      })
      .catch((error) => {  });
  };

  return <ButtonA name="Login" onHandleClick={handleProcssesSignIn} />;
};
