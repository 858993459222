import { FC, useState } from "react";
import _ from "lodash";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Asterisk } from "../../common/components/Asterisk";
import { upperCaseFirstLetter } from "../../common/miscellaneous/upperCaseFirstLetter";
import { useFormContext } from "react-hook-form";

type QuestionTextProps = {
  questionId: string;
  questionItem: any;
  // list question from dto
  questions: any[];
  setQuestions: any;
};

export const QuestionTextInput: FC<QuestionTextProps> = ({
  questionId,
  questionItem,
  questions,
  setQuestions,
}) => {
  const { register, control, clearErrors } = useFormContext();

  return (
    <FormControl fullWidth key={questionId} sx={{ marginTop: "23px" }}>
      <label className="font-bold">
        {upperCaseFirstLetter(questionItem.question)}{" "}
        {questionItem.isRequired ? <Asterisk /> : null}
      </label>
      <TextField
        {...register(`text-${questionItem.id}`, {
          required: questionItem.isRequired
            ? "You must answer this question"
            : false,
        })}
        defaultValue={questionItem.value ?? ""}
        sx={{
          "& .MuiInputBase-input": {
            height: "5px",
          },
        }}
        onChange={(event) => {
          clearErrors(`text-${questionItem.id}`);
          const objWithAnswer = _.map(questions, (ques) => {
            return ques.id === questionItem.id
              ? { ...ques, value: event.target.value }
              : ques;
          });
          setQuestions(objWithAnswer);
        }}
      />
      <p className="italic">
        {upperCaseFirstLetter(questionItem.questionDescription ?? "")}
      </p>
      <FormHelperText sx={{ color: "red" }}>
        {control.getFieldState(`text-${questionItem.id}`).error?.message}
      </FormHelperText>
    </FormControl>
  );
};
