import axios from "axios";

export const fetchImportUsers = async (data: any) => {
  const body = { file: data.file };

  return await axios.post(`/api/user/company/${data.companyId}/import`, body, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
  });
};
