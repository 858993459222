import { FC } from "react";
import { ModalA } from "../../common/components/ModalA";
import ReCAPTCHA from "react-google-recaptcha";
import { ButtonA } from "../../common/components/ButtonA";

type CapchaTokenModalProps = {
    isShowModal: boolean;
    setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setCaptChaToken: React.Dispatch<React.SetStateAction<string>>;
    content: string;
};

const ButtonOKStyle = {
    backgroundColor: "#203C77",
    color: "#fff",
    borderRadius: "50px",
    minWidth: "100px",
    width: "100%",
    fontWeight: 100,
    "&:hover": {
        backgroundColor: "#203C77",
    },
    textTransform: "capitalize",
};

export const CapchaTokenModal: FC<CapchaTokenModalProps> = ({
    isShowModal,
    setIsShowModal,
    setCaptChaToken,
    content
}) => {
    const handleCloseModal = () => {
        setIsShowModal(false);
    };

    function handleSetCapchaToken(value: string) {
        if (value) {
            setCaptChaToken(value);
        }
    };

    return (
        <ModalA
            title="Capcha Verification"
            onCloseModal={handleCloseModal}
            isOpen={isShowModal}
        >
            <p>
                { content }
            </p>
            <p className="text-center mt-3 mb-5 flex justify-center">
                <ReCAPTCHA
                    sitekey="6LfA8espAAAAAG3fTbYTu_i2URP-TZT39vxDhIVK"
                    onChange={handleSetCapchaToken as any}
                />
            </p>

            <ButtonA
                name="OK"
                onHandleClick={handleCloseModal}
                customStyle={ButtonOKStyle}
            />
        </ModalA>
    );
};
