import { useMutation } from "react-query";
import _ from "lodash";
import { FC } from "react";
import { DeleteButton } from "../../common/components/DeleteButton";
import { fetchDeleteTaskSubmission } from "../miscellaneous/fetchDeleteTaskSubmission";

type DeleteTaskSubmisstionProps = {
  id: string;
  info: string;
  taskSubmission: any;
  setTaskSubmission: any;
};

export const DeleteTaskSubmission: FC<DeleteTaskSubmisstionProps> = ({
  id,
  info,
  taskSubmission,
  setTaskSubmission,
}) => {
  const mutationDelete = useMutation(fetchDeleteTaskSubmission, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const tasksFilter = _.filter(taskSubmission, (item) => {
          return item.id !== id;
        });
        setTaskSubmission(tasksFilter);
      }
    },
    onError: () => {},
  });

  const handleDelete = (event: any) => {
    event.stopPropagation();
    mutationDelete.mutate(id);
  };

  return (
    <DeleteButton
      id={id}
      title={"Delete Task"}
      info={info}
      customContent={"Are you sure that you want to delete this task?"}
      onHandleDelete={handleDelete}
    />
  );
};
