import { FC } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { DeleteTaskSubmission } from "./DeleteTaskSubmission";
import { EditButton } from "../../common/components/EditButton";
import { useNavigate } from "react-router-dom";
import { TableFooterCommon } from "../../common/components/TableFooterCommon";
import { TableNoContent } from "../../common/components/TableNoContent";
import { styled } from "@mui/material/styles";

const tableCellHeadStyle = {
  fontWeight: 600,
  minWidth: "170px",
};

const StyledTableHead = styled(TableHead)`
  & .MuiTableCell-root {
    background-color: #f5f4f4;
  }
`;

type TaskSubmissionTableProps = {
  tasksSubmission: any;
  setTasksSubmission: any;
  page: number;
  rowsPerPage: number;
  totalRecords: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const TaskSubmissionList: FC<TaskSubmissionTableProps> = ({
  tasksSubmission,
  setTasksSubmission,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const userRole = !!localStorage.getItem("rolesJson")
    ? JSON.parse(localStorage.getItem("rolesJson") as string)
    : [];

  const isRoleAmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLowerCase() === "admin";

  const isRoleSupperAdmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLowerCase() === "super admin";

  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/update-task/${id}`);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 700 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 500 }}
          aria-label="custom pagination table"
        >
          <StyledTableHead>
            <TableRow>
              <TableCell sx={{ maxWidth: "150px", fontWeight: 600 }}>
                ID
              </TableCell>
              <TableCell sx={{ fontWeight: 600, minWidth: "270px" }}>
                Task Name
              </TableCell>
              <TableCell sx={{ fontWeight: 600, minWidth: "270px" }}>
                Outcome
              </TableCell>
              {isRoleAmin || isRoleSupperAdmin ? (
                <>
                  <TableCell sx={{ fontWeight: 600, minWidth: "270px" }}>
                    Submitted By
                  </TableCell>
                  <TableCell sx={tableCellHeadStyle}>Date Submitted</TableCell>
                </>
              ) : null}
              <TableCell sx={{ fontWeight: 600, minWidth: "150px" }}>
                Start Time
              </TableCell>
              <TableCell sx={{ fontWeight: 600, minWidth: "150px" }}>
                End Time
              </TableCell>
              <TableCell sx={tableCellHeadStyle} align="center">
                Action
              </TableCell>
            </TableRow>
          </StyledTableHead>

          <TableBody>
            {(tasksSubmission ?? []).length > 0 ? (
              tasksSubmission.map((row: any) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell width={"40"} scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell width={"160"}>{row.taskName}</TableCell>
                    <TableCell
                      width={"160"}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {row.outcome}
                    </TableCell>
                    {isRoleAmin || isRoleSupperAdmin ? (
                      <>
                        <TableCell
                          width={"100"}
                          sx={{ textTransform: "lowercase" }}
                        >
                          {row.createdBy}
                        </TableCell>
                        <TableCell width={"50"}>
                          {!!row.dateCompleted
                            ? dayjs(row.dateCompleted).format("MM/DD/YYYY")
                            : ""}
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell width={"150"}>
                      {dayjs(row.startTime).format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    <TableCell width={"150"}>
                      {dayjs(row.endTime).format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    <TableCell width={"60"} align="center">
                      <div>
                        <EditButton
                          id={row.id}
                          onHandleEdit={() => {
                            handleEdit(row.id);
                          }}
                        />

                        <DeleteTaskSubmission
                          id={row.id}
                          info={row.taskName}
                          taskSubmission={tasksSubmission}
                          setTaskSubmission={setTasksSubmission}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableNoContent />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex justify-end">
        <TableFooterCommon
          data={tasksSubmission}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          onHandleChangePage={handleChangePage}
          onHandleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </Paper>
  );
};
