import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { SectionTitle } from "../../common/components/SectionTitle";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Asterisk } from "../../common/components/Asterisk";
import { useCreateTemplate } from "../hooks/useCreateTemplate";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TaskTemplateForm } from "./TaskTemplateForm";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import { useUpdateTemplate } from "../hooks/useUpdateTemplate";
import { CopyFromExistsTemplate } from "./CopyFromExistsTemplate";
import { TaskDetailType } from "../types/TaskTemplateType";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const formControlStyle = {
  marginTop: "20px",
};

export type Option = {
  id: string;
  title: string;
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

export const UpdateTemplateDetail: FC = () => {
  const methods = useForm({});
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
  } = methods;
  const navigate = useNavigate();
  const { id } = useParams();

  const { companiesName, tasksType, questionsType } = useCreateTemplate();

  const [isShowExistsTemplate, setIsShowExistsTemplate] = useState(false);
  // const [taskDetailCopy, setTaskDetailCopy] = useState<TaskDetailType>({
  //   companyId: null,
  //   taskName: null,
  //   jobTitleIds: null as any,
  //   taskTypeId: null,
  //   taskTemplateDetailDtos: [],
  //   jobTitles: null,
  // });

  const {
    taskDetail,
    setTaskDetail,
    mutationGetDetail,
    mutationUpdateTaskTemplate,
    mutationGetJobsByCompany,
    jobTitle,
    tasksTemplate,
    setTasksTemplate,
  } = useUpdateTemplate();

  useEffect(() => {
    mutationGetDetail.mutate(id as string);
  }, []);

  const handleCreateNewQuestion = () => {
    setTasksTemplate([
      ...tasksTemplate,
      {
        id: uuidv4(),
        question: "",
        questionTypeId: 1,
        questionDescription: "",
        questionChoices: "",
        isRequired: false,
      },
    ]);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleApplyExist = (data: TaskDetailType) => {
    // setTaskDetailCopy(data);

    // Get job title for render the job title input.
    // mutationGetJobsByCompany.mutate(data.companyId as string);

    // Set questions data for render on UI.
    setTasksTemplate(data.taskTemplateDetailDtos as any);

    // Reset for make useForm receive new value.
    // reset({
    // organization: data.companyId,
    // taskName: data.taskName,
    // jobTitle: data.jobTitles
    // });
  };

  const submitUpdateTemplate = (data: any) => {
    const taskTemplateDetail = (tasksTemplate ?? []).map((item) => {
      return _.omit(item, "id");
    });

    const payload = {
      id: id,
      companyId: data.organization,
      taskName: data.taskName,
      jobTitleIds: _.map(data.jobTitle ?? taskDetail.jobTitles ?? [], "id"),
      jobTitles: data.jobTitle ?? taskDetail.jobTitles,
      taskTypeId: data.taskType,
      orderNumber: data.orderNumber ?? "",
      taskTemplateDetailDtos: taskTemplateDetail,
    };

    mutationUpdateTaskTemplate.mutate(payload);
  };

  const isFindExistJob = () => {
    if (!!taskDetail) {
      const jobId = taskDetail.jobTitles;
      const jobTitleSelected = _.map(jobId, "id");
      const jobTitleAvailable = _.map(jobTitle ?? [], "id");

      if (_.indexOf(jobTitleAvailable, 2)) {
      }
      return jobTitleAvailable.some((item) =>
        jobTitleSelected.some((element) => element === item)
      );
    }

    return false;
  };

  return !!taskDetail ? (
    <div className="mt-9">
      <SectionTitle name="Update Template" />

      <Box sx={{ minWidth: 120, marginTop: "60px", marginBottom: "120px" }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitUpdateTemplate)}>
            <span className="text-sub-title">Task Summary Information</span>
            <div className={"flex flex-col"}>
              <div className="mr-10 ml-10">
                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">
                    Organization <Asterisk />
                  </label>
                  <Controller
                    control={control}
                    name="organization"
                    rules={{ required: "Organization is required" }}
                    defaultValue={taskDetail.companyId ?? ""}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        id="organization"
                        value={taskDetail.companyId ?? ""}
                        onChange={(e) => {
                          setValue("organization", e.target.value);

                          setTaskDetail({
                            ...taskDetail,
                            companyId: e.target.value,
                          });

                          clearErrors("organization");
                          mutationGetJobsByCompany.mutate(e.target.value);
                        }}
                        style={{ height: "38px" }}
                      >
                        {(companiesName ?? []).map((item: any) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("organization").error?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">
                    Task Name <Asterisk />
                  </label>
                  <TextField
                    {...register("taskName", {
                      required: "Task Name is required",
                    })}
                    value={[taskDetail.taskName ?? ""]}
                    onChange={(event) => {
                      event.stopPropagation();

                      setTaskDetail({
                        ...taskDetail,
                        taskName: event.target.value,
                      });
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "5px",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("taskName").error?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">Job Title</label>
                  <Controller
                    control={control}
                    name="jobTitle"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        multiple
                        id="checkboxes-tags"
                        options={jobTitle ?? []}
                        disableCloseOnSelect
                        value={isFindExistJob() ? taskDetail.jobTitles : []}
                        getOptionLabel={(option: Option) => option.title}
                        isOptionEqualToValue={(option, value) =>
                          value === undefined || option.id === value.id
                        }
                        onChange={(event, data) => {

                          setTaskDetail({ ...taskDetail, jobTitles: data });

                          setValue("jobTitle", data);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">
                    Task Type <Asterisk />
                  </label>
                  <Controller
                    control={control}
                    name="taskType"
                    rules={{ required: "Task Type is required" }}
                    defaultValue={taskDetail.taskTypeId ?? 1}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        id="taskType"
                        value={value ?? ""}
                        onChange={(e) => {
                          setValue("taskType", e.target.value);
                          clearErrors("taskType");
                        }}
                        style={{ height: "38px" }}
                      >
                        {(tasksType ?? []).map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("taskType").error?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">Task Order</label>
                  <TextField
                    type="number"
                    {...register("orderNumber", {
                      valueAsNumber: true,
                      validate: (value) => {
                        if (!Number.isInteger(value)) {
                          return "Task Order value must be an integer number";
                        }
                        if (value < 0 || value > 1000) {
                          return "Task Order value is between 1 and 1000";
                        }

                        return true;
                      }
                    })}
                    value={taskDetail.orderNumber ?? ""}
                    onChange={(event) => {
                      event.stopPropagation();

                      setTaskDetail({
                        ...taskDetail,
                        orderNumber: event.target.value,
                      });
                      setValue("orderNumber", event.target.value);
                      clearErrors("orderNumber");
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "5px",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("orderNumber").error?.message}
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="mt-4 mr-10 ml-10">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-sub-title">
                    Task Summary Information
                  </span>

                  <div>
                    <Button
                      onClick={handleCreateNewQuestion}
                      sx={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#203C77",
                      }}
                    >
                      <AddIcon /> Add New Question
                    </Button>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsShowExistsTemplate(true);
                      }}
                      sx={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        color: "#203C77",
                      }}
                    >
                      <ContentCopyIcon /> Copy from existing template
                    </Button>
                  </div>
                </div>

                {_.map(tasksTemplate, (item: any, index) => {
                  return (
                    <TaskTemplateForm
                      key={item.id}
                      index={item.id}
                      value={item}
                      setListTaskTemplate={setTasksTemplate}
                      listTaskTemplate={tasksTemplate}
                      questionsType={questionsType}
                    />
                  );
                })}
              </div>

              <FormControl fullWidth sx={btnGroupStyle}>
                <ButtonCancelA
                  name="Cancel"
                  onHandleClick={handleCancel}
                  icon={<CancelIcon sx={{ marginRight: "4px" }} />}
                />
                <ButtonSubmitA
                  name="Save"
                  icon={<SaveIcon sx={{ marginRight: "4px" }} />}
                />
              </FormControl>
            </div>
          </form>
        </FormProvider>
      </Box>
      {isShowExistsTemplate ? (
        <CopyFromExistsTemplate
          isShowBox={isShowExistsTemplate}
          setIsShowBox={setIsShowExistsTemplate}
          companiesName={companiesName}
          onHandleApplyCopy={handleApplyExist}
        />
      ) : null}
    </div>
  ) : null;
};
