import { FC, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { OrganizationSearch } from "./OrganizationSearch";
import { OrganizationTable } from "./OrganizationTable";
import { useSetCompaniesState } from "../hooks/useSetCompaniesState";
import { useCompaniesValue } from "../hooks/useCompaniesValue";
import { fetchQueryCompanies } from "../miscellaneous/fetchQueryCompanies";
import { usePaginationTable } from "../../common/hooks/usePaginationTable";

export const OrganizationManagement: FC = () => {
  const setCompanies = useSetCompaniesState();
  const companies = useCompaniesValue();
  const [companySearch, setCompanySearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const { register, handleSubmit, reset } = useForm();

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
  } = usePaginationTable(companies);

  const mutationQueryCompanies = useMutation(fetchQueryCompanies, {
    onSuccess: (res) => {
      setCompanies(res.data.data);
      setTotalRecords(res.data.totalRecords);
    },
    onError: () => {},
  });

  useEffect(() => {
    const payload = {
      companyName: companySearch,
      pageSize: rowsPerPage,
      pageNumber: page,
    };

    mutationQueryCompanies.mutate(payload);
  }, [JSON.stringify(companySearch), page, rowsPerPage]);

  return (
    <>
      <OrganizationSearch
        register={register}
        handleSubmit={handleSubmit}
        reset={reset}
        setCompanySearch={setCompanySearch}
        setPage={setPage}
      />
      <OrganizationTable
        page={page}
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
