import { FC, useEffect, useState } from "react";
import _ from "lodash";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { upperCaseFirstLetter } from "../../common/miscellaneous/upperCaseFirstLetter";
import { Controller, useFormContext } from "react-hook-form";
import { Asterisk } from "../../common/components/Asterisk";

type QuestionCheckBoxesProps = {
  questionId: string;
  questionItem: any;
  // list question from dto
  questions: any[];
  setQuestions: any;
};

export const QuestionCheckBoxes: FC<QuestionCheckBoxesProps> = ({
  questionId,
  questionItem,
  questions,
  setQuestions,
}) => {
  const {
    control,
    clearErrors,
    setValue,
    getValues,
    setError,
  } = useFormContext();

  const choiceOptions = _.split(questionItem.questionChoices, ";");

  const questionAnswer = _.split(questionItem.value, ";") ?? [];
  const answerFilter = _.filter(questionAnswer, item => item !== "");
  const [defaultAnswer, setDefaultAnswer] = useState(answerFilter ?? []);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (questionItem.isRequired && isChecked) {
      const isNotAnswer =
        (defaultAnswer ?? []).filter((item) => item !== "").length === 0;

      if (isNotAnswer) {
        setError(`check-boxes-${questionItem.id}`, {
          message: "You must answer this question",
        });
      } else {
        clearErrors(`check-boxes-${questionItem.id}`);
      }
    }
  }, [defaultAnswer]);

  return !!questionItem.id ? (
    <FormControl fullWidth key={questionId} sx={{ marginTop: "23px" }}>
      <label className="font-bold">
        {upperCaseFirstLetter(questionItem.question)}
        {questionItem.isRequired ? <Asterisk /> : null}
      </label>

      <Controller
        control={control}
        name={`check-boxes-${questionItem.id}`}
        defaultValue={defaultAnswer}
        rules={{
          required: questionItem.isRequired
            ? "You must answer this question"
            : false,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormGroup>
            {_.map(choiceOptions, (choose: string) => {
              return (
                <FormControlLabel
                  key={choose}
                  checked={defaultAnswer.includes(choose)}
                  control={
                    <Checkbox
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#203c77",
                        },
                      }}
                      onClick={(event: any) => {
                        setIsChecked(true);                    
                        if (event.target.checked) {
                          setDefaultAnswer([...defaultAnswer, choose]);

                          setValue(`check-boxes-${questionItem.id}`, [
                            ...defaultAnswer,
                            choose,
                          ]);

                          const objWithAnswer = _.map(questions, (ques) => {
                            const userAnswers = _.split(
                              questionItem.value,
                              ";"
                            ).filter((ob) => {
                              return ob !== "";
                            });

                            return ques.id === questionItem.id
                              ? {
                                  ...ques,
                                  // add user's option to below
                                  value: _.join([...userAnswers, choose], ";"),
                                }
                              : ques;
                          });

                          setQuestions(objWithAnswer);
                        } else {
                          setDefaultAnswer(
                            _.remove(defaultAnswer, (item) => {
                              return item !== choose;
                            })
                          );

                          const checkBoxSelect = getValues(
                            `check-boxes-${questionItem.id}`
                          );

                          setValue(
                            `check-boxes-${questionItem.id}`,
                            _.remove(checkBoxSelect, (item) => {
                              return item !== choose;
                            })
                          );

                          const objWithAnswer = _.map(questions, (ques) => {
                            const userAnswers = _.split(
                              questionItem.value,
                              ";"
                            ).filter((ob) => {
                              return ob !== "";
                            });

                            return ques.id === questionItem.id
                              ? {
                                  ...ques,
                                  // add user's option to below
                                  value: _.remove(userAnswers, (item) => {
                                    return item !== choose;
                                  }).join(";"),
                                }
                              : ques;
                          });

                          setQuestions(objWithAnswer);
                        }
                      }}
                    />
                  }
                  label={choose}
                />
              );
            })}
          </FormGroup>
        )}
      ></Controller>

      <p className="italic">
        {upperCaseFirstLetter(questionItem.questionDescription ?? "")}
      </p>
      <FormHelperText sx={{ color: "red" }}>
        {control.getFieldState(`check-boxes-${questionItem.id}`).error?.message}
      </FormHelperText>
    </FormControl>
  ) : null;
};
