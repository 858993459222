export function Footer() {
  return (
    <footer className="main-footer flex justify-center items-center dark:bg-gray-800 border-t-2">
      <span className="font-bold">
        © COPYRIGHT 2024 IMPLEMENTATION PARTNERS LLC. ALL RIGHTS RESERVED.
      </span>
      <span className="pl-1 pr-1 font-bold"> | </span>
      <span className="main-button-color font-bold">
        <a href="https://www.implementationpartners.com/privacy/">PRIVACY POLICY</a>
      </span>
    </footer>
  );
}
