import { FC } from "react";
import { useMutation } from "react-query";
import _ from "lodash";
import { DeleteButton } from "../../common/components/DeleteButton";
import { fetchDeleteTaskTemplate } from "../miscellaneous/fetchDeleteTaskTemplate";

type DeleteTaskTemplateProps = {
  id: string;
  info: string;
  tasks: any;
  setTasks: any;
};

export const DeleteTaskTemplate: FC<DeleteTaskTemplateProps> = ({
  id,
  info,
  tasks,
  setTasks,
}) => {
  const mutationDelete = useMutation(fetchDeleteTaskTemplate, {
    onSuccess: (res) => {
      if (res.status === 200) {
          const tasksFilter = _.filter(tasks, (item) => {
            return item.id !== id;
          });
          setTasks(tasksFilter);
      }
    },
    onError: () => {},
  });

  const handleDelete = (event: any) => {
    event.stopPropagation();
    mutationDelete.mutate(id);
  };

  return (
    <DeleteButton
      id={id}
      title={"Delete Task Name"}
      info={info}
      onHandleDelete={handleDelete}
    />
  );
};
