import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MuiChipsInput } from "mui-chips-input";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation } from "react-query";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Asterisk } from "../../common/components/Asterisk";
import { SectionTitle } from "../../common/components/SectionTitle";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { fetchCreateCompany } from "../miscellaneous/fetchCreateCompany";

const formControlStyle = {
  marginTop: "20px",
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

export const CreateNewCompany: FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const [chips, setChips] = useState([]);

  const mutateCreateCompany = useMutation(fetchCreateCompany, {
    onSuccess: (res) => {
      if (res.status === 200) {
        navigate("/company-management");
      }
    },
    onError: () => {},
  });

  const onSubmit = (data: any) => {
    const payload = {
      companyName: data.companyName ?? "",
      jobTitles: chips ?? [],
      isActive: data.status === "active" ? true : false
    };

    mutateCreateCompany.mutate(payload);
  };

  const handleChange = (newChips: any) => {
    setChips(newChips);
  };

  const handleBackToCompanies = () => {
    navigate("/company-management");
  };

  return (
    <div className="mt-9">
      <SectionTitle name="Create New Company" />
      <Box sx={{ minWidth: 120, marginTop: "60px" }}>
        <form className="pr-24 pl-24 pb-24" onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Organization <Asterisk />
            </label>
            <TextField
              {...register("companyName", {
                required: "Organization is required",
              })}
              defaultValue={""}
              sx={{
                "& .MuiInputBase-input": {
                  height: "5px",
                },
              }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("companyName").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">Job Title</label>
            <MuiChipsInput value={chips} onChange={handleChange} />
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">Status</label>
            <Controller
              control={control}
              name="status"
              defaultValue={"active"}
              rules={{ required: "Status is required" }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="status"
                  value={value ?? "active"}
                  onChange={(e) => {
                    setValue("status", e.target.value);
                    clearErrors("status");
                  }}
                  style={{ height: "38px" }}
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("status").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={btnGroupStyle}>
            <ButtonCancelA
              name="Cancel"
              onHandleClick={handleBackToCompanies}
              icon={<CancelIcon sx={{ marginRight: "4px" }} />}
            />
            <ButtonSubmitA
              name="Save"
              icon={<SaveIcon sx={{ marginRight: "4px" }} />}
            />
          </FormControl>
        </form>
      </Box>
    </div>
  );
};
