import { FC } from "react";
import _ from "lodash";
import { QuestionTextInput } from "./QuestionTextInput";
import { QuestionCheckBoxes } from "./QuestionCheckBoxes";
import { useTaskQuestionsValue } from "../hooks/useTaskQuestionsValue";
import { useSetTaskQuestionsState } from "../hooks/useSetTaskQuestionsState";
import { QuestionRadioButton } from "./QuestionRadioButton";
import { QuestionSingleSelect } from "./QuestionSingleSelect";
import { QuestionMultiSelect } from "./QuestionMultiSelect";
import { sortQuestionsTemplate } from "../miscellaneous/sortQuestionsTemplate";

export const QuestionsModule: FC = () => {
  const setQuestions = useSetTaskQuestionsState();
  const questionsValue = useTaskQuestionsValue();

  const questionFormat = sortQuestionsTemplate(questionsValue);

  return (
    <>
      {_.map(questionFormat, (item) => {
        switch (item.questionTypeId) {
          case 1:
            return (
              <QuestionTextInput
                key={item.id}
                questionId={item.id}
                questionItem={item}
                questions={questionsValue}
                setQuestions={setQuestions}
              />
            );
          case 2:
            return (
              <QuestionSingleSelect
                key={item.id}
                questionId={item.id}
                questionItem={item}
                questions={questionsValue}
                setQuestions={setQuestions}
              />
            );
          case 3:
            return (
              <QuestionMultiSelect
                key={item.id}
                questionId={item.id}
                questionItem={item}
                questions={questionsValue}
                setQuestions={setQuestions}
              />
            );
          case 4:
            return (
              <QuestionCheckBoxes
                key={item.id}
                questionId={item.id}
                questionItem={item}
                questions={questionsValue}
                setQuestions={setQuestions}
              />
            );
          case 5:
            return (
              <QuestionRadioButton
                key={item.id}
                questionId={item.id}
                questionItem={item}
                questions={questionsValue}
                setQuestions={setQuestions}
              />
            );
          default:
            return <div key={item.id}></div>;
        }
      })}
    </>
  );
};
