import { useEffect, useState } from "react";
import { ModalA } from "./ModalA";
import axios from "axios";
import { ButtonA } from "./ButtonA";

const cancelBtnStyle = {
  backgroundColor: "#fff",
  color: "#172952",
  width: "100px",
  fontWeight: 600,
  border: "1.5px solid",
  borderRadius: "50px",
  textTransform: "capitalize",
};

export const ServerErrorCommon = () => {
  const [isShow, setIsShow] = useState<boolean>(false);

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error && error.code === "ERR_NETWORK") {
        setIsShow(true);
        return Promise.reject(error);
      }

      if (error && error.response && error.response.status === 500) {
        setIsShow(true);
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  const handleCloseModal = () => {
    setIsShow(false);
  };

  return isShow ? (
    <ModalA title="Server Error" isOpen={true} onCloseModal={handleCloseModal}>
      <div className="flex flex-col justify-center items-center">
        <span className="mt-5 mb-5 text-lg">
          Something went wrong. Please try later.
        </span>
        <ButtonA
          name="Close"
          customStyle={cancelBtnStyle}
          onHandleClick={handleCloseModal}
        />
      </div>
    </ModalA>
  ) : null;
};
