import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { FC, useState } from "react";

type TextInputProps = {
  label: string;
  value: string;
  register: any;
  onChange?: () => void;
  labelStyle?: {};
};

export const TextInput: FC<TextInputProps> = ({
  label,
  value,
  onChange,
  register,
  labelStyle
}) => {
  return (
    <div className="flex flex-row item items-center">
      {/* <InputLabel sx={labelStyle ?? { marginRight: "12px" }}>{label}</InputLabel> */}
      <label className="font-bold mr-3">{label}</label>
      <TextField
        {...register}
        defaultValue={value}
        onChange={onChange}
        sx={{
          "& .MuiInputBase-input": {
            height: "5px",
          },
        }}
      />
    </div>
  );
};
