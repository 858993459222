import {
  Autocomplete,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { FC, useState } from "react";
import _ from "lodash";
import { upperCaseFirstLetter } from "../../common/miscellaneous/upperCaseFirstLetter";
import { Controller, useFormContext } from "react-hook-form";
import { Asterisk } from "../../common/components/Asterisk";
import { OutcomeTooltip } from "./OutcomeTooltip";
import { textAreaStyle } from "../../tasks-list-management/styles/TaskTemplateFormStyle";
import { useSetOutcomeComment } from "../hooks/useSetOutcomeComment";
import { useOutcomeCommentValue } from "../hooks/useOutcomeCommentValue";

type QuestionSingleSelectProps = {
  questionId: string;
  questionItem: any;
  // list question from dto
  questions: any[];
  setQuestions: any;
};

export const QuestionSingleSelect: FC<QuestionSingleSelectProps> = ({
  questionId,
  questionItem,
  questions,
  setQuestions,
}) => {
  const { register, control, clearErrors, reset, setValue } = useFormContext();
  const setOutcomeComment = useSetOutcomeComment();
  const outcomeComment = useOutcomeCommentValue();

  const choiceOptions = _.split(questionItem.questionChoices, ";");
  const [values, setValues] = useState(questionItem.value ?? "");

  const handleChange = (event: any) => {
    setValue(`sign-${questionItem.id}`, event.target.value);
    setValues(event.target.value);

    const quesWithAnswer = (questions ?? []).map((item) => {
      return item.id === questionItem.id
        ? { ...item, value: event.target.value }
        : item;
    });

    clearErrors(`sign-${questionItem.id}`);

    setQuestions(quesWithAnswer);
  };

  return (
    <>
      <FormControl key={questionId} fullWidth sx={{ marginTop: "23px" }}>
        <label className="font-bold">
          {upperCaseFirstLetter(questionItem.question)}
          {questionItem.isRequired ? <Asterisk /> : null}
          <span className="ml-2">
            {questionItem.question.toLowerCase() === "outcome" ? (
              <OutcomeTooltip />
            ) : null}
          </span>
        </label>
        <Controller
          control={control}
          name={`sign-${questionItem.id}`}
          defaultValue={values}
          rules={{
            required: questionItem.isRequired
              ? "You must answer this question"
              : false,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            // <Select
            //   id={`sign-${questionItem.id}`}
            //   name={`sign-${questionItem.id}`}
            //   value={values}
            //   onChange={handleChange}
            //   style={{ height: "38px" }}
            //   sx={{ textTransform: "capitalize" }}
            // >
            //   {_.map(choiceOptions, (choose: string) => {
            //     return (
            //       <MenuItem key={choose} value={choose} sx={{ textTransform: "capitalize" }}>
            //         {choose}
            //       </MenuItem>
            //     );
            //   })}
            // </Select>

            <Autocomplete
              disablePortal
              id={`sign-${questionItem.id}`}
              options={choiceOptions ?? []}
              value={values}
              onChange={(event, data) => {

                setValue(`sign-${questionItem.id}`, data);
                setValues(data);
            
                const quesWithAnswer = (questions ?? []).map((item) => {
                  return item.id === questionItem.id
                    ? { ...item, value: data }
                    : item;
                });
            
                clearErrors(`sign-${questionItem.id}`);
            
                setQuestions(quesWithAnswer);

              }}
              renderOption={(props, option: any, { selected }) => {
                return (
                  <li key={option} value={option} {...props}>
                    <span className="capitalize">{option}</span>
                  </li>
                );
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "5px",
                        textTransform: "capitalize"
                      },
                    }}
                  />
                );
              }}
            />
          )}
        ></Controller>

        <p className="italic">
          {upperCaseFirstLetter(questionItem.questionDescription ?? "")}
        </p>
        <FormHelperText sx={{ color: "red" }}>
          {control.getFieldState(`sign-${questionItem.id}`).error?.message}
        </FormHelperText>
      </FormControl>

      {questionItem.question.toLowerCase() === "outcome" &&
      values === "other" ? (
        <FormControl fullWidth sx={{ marginTop: "23px" }}>
          <label className="font-bold">Comments</label>
          <TextareaAutosize
            placeholder=""
            // value={comments ?? ""}
            value={outcomeComment}
            minRows={3}
            onChange={(event) => {
              // setComments(event.target.value);
              setOutcomeComment(event.target.value);
            }}
            style={textAreaStyle}
          />
        </FormControl>
      ) : null}
    </>
  );
};
