import { FC } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { InputLabel, MenuItem, Select } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { SearchButton } from "../../common/components/SearchButton";
import { TextInput } from "../../common/components/TextInput";

type JobsSearchProps = {
  control: Control<FieldValues, any, FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues, FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setJobsSearch: any;
  companies: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const JobsSearch: FC<JobsSearchProps> = ({
  control,
  register,
  handleSubmit,
  setValue,
  reset,
  setJobsSearch,
  companies,
  setPage
}) => {
  const handleSubmitSearch = (data: any) => {
    setJobsSearch({ companyId: data.organization ?? "", title: data.jobTitle });
    setPage(0);
  };

  const handleReset = () => {
    reset();
    setJobsSearch({ companyId: "", title: "" });
    setPage(0);
  };

  return (
    <div className="mt-9 border-2 pt-7 pb-7">
      <form onSubmit={handleSubmit(handleSubmitSearch)}>
        <div className="flex flex-row justify-center items-center gap-8">
          <div className="flex flex-row justify-center items-center">
            {/* <InputLabel sx={{ marginRight: "12px" }}>Organization</InputLabel> */}
            <label className="font-bold mr-3">Organization</label>
            <Controller
              control={control}
              name="organization"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="organization"
                  value={value ?? ""}
                  onChange={(e) => {
                    setValue("organization", e.target.value);
                  }}
                  style={{ height: "38px", width: "230px" }}
                >
                  {companies.map((item: any) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            ></Controller>
          </div>

          <div>
            <TextInput
              label="Job Title"
              register={{ ...register("jobTitle") }}
              value={""}
            />
          </div>
        </div>

        <div className="flex flex-center justify-center mt-10 gap-5">
          <ButtonCancelA
            name="Reset"
            onHandleClick={handleReset}
            icon={<ClearIcon />}
          />
          <SearchButton />
        </div>
      </form>
    </div>
  );
};
