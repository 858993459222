import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StartIcon from "@mui/icons-material/Start";
import { TaskSubmissionList } from "./TaskSubmissionList";
import { TaskSubmissionSearch } from "./TaskSubmissionSearch";
import { SectionTitle } from "../../common/components/SectionTitle";
import { ButtonA } from "../../common/components/ButtonA";
import { useGetTaskSubmission } from "../hooks/useGetTaskSubmission";
import { usePaginationTable } from "../../common/hooks/usePaginationTable";
import { TaskSubmissionAdminSearch } from "./TaskSubmissionAdminSearch";
import dayjs from "dayjs";
import { NotifyExportModal } from "./NotifyExportModal";
import { ButtonALoading } from "../../common/components/ButtonALoading";
import { CapchaTokenModal } from "./CapchaTokenModal";

export const TaskSubmission: FC = () => {
  const navigate = useNavigate();
  const [isShowNotifyModal, setIsShowNotifyModal] = useState(false);

  const [isShowCapchaModal, setIsShowCapchaModal] = useState(false);
  const [captChaToken, setCaptChaToken] = useState(null);

  const {
    tasksSubmission,
    setTasksSubmission,
    totalRecords,
    mutateGetAllTaskSubmissison,
    mutateExportExcel,
  } = useGetTaskSubmission(setIsShowNotifyModal);

  const {
    page,
    rowsPerPage,
    emptyRows,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
  } = usePaginationTable(tasksSubmission);

  const [taskSubmitSearch, setTaskSubmitSearch] = useState({
    taskId: 0,
    taskName: "",
    dateCompleted: null,
    from: null,
    to: null,
    companyId: "",
    jobTitles: null,
  });

  useEffect(() => {
    const payload = {
      pageNum: page + 1,
      pageSize: rowsPerPage,
      taskId: taskSubmitSearch.taskId ?? 0,
      taskName: taskSubmitSearch.taskName ?? "",
      from: !!taskSubmitSearch.from
        ? dayjs(taskSubmitSearch.from).format()
        : null,
      dateCompleted: !!taskSubmitSearch.dateCompleted
        ? dayjs(taskSubmitSearch.dateCompleted)
        : null,
      to: !!taskSubmitSearch.to ? dayjs(taskSubmitSearch.to).format() : null,
      companyId: taskSubmitSearch.companyId,
      jobTitles: taskSubmitSearch.jobTitles,
      timezone: dayjs().format(),
    };

    mutateGetAllTaskSubmissison.mutate(payload);
  }, [page, rowsPerPage, JSON.stringify(taskSubmitSearch)]);

  const userRole = !!localStorage.getItem("rolesJson")
    ? JSON.parse(localStorage.getItem("rolesJson") as string)
    : [];

  const isRoleAmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLowerCase() === "admin";

  const isRoleSupperAdmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLowerCase() === "super admin";

  const handleExportExcel = () => {
    if (!captChaToken) {
      setIsShowCapchaModal(true);
    } else {
      const payload = {
        pageNum: page,
        pageSize: rowsPerPage,
        taskId: taskSubmitSearch.taskId ?? 0,
        taskName: taskSubmitSearch.taskName ?? "",
        from: taskSubmitSearch.from,
        dateCompleted: taskSubmitSearch.dateCompleted,
        to: taskSubmitSearch.to,
        companyId: taskSubmitSearch.companyId,
        jobTitles: taskSubmitSearch.jobTitles,
        timezone: dayjs().format(),
      };

      mutateExportExcel.mutate(payload);
    }
  };

  const buttonExportJSX = () => {
    if (mutateExportExcel.isLoading) {
      return <ButtonALoading name={"Export"} />;
    } else {
      return <ButtonA name={"Export"} onHandleClick={handleExportExcel} />;
    }
  };
  useEffect(() => {
    if (captChaToken) {
      const payload = {
        pageNum: page,
        pageSize: rowsPerPage,
        taskId: taskSubmitSearch.taskId ?? 0,
        taskName: taskSubmitSearch.taskName ?? "",
        from: taskSubmitSearch.from,
        dateCompleted: taskSubmitSearch.dateCompleted,
        to: taskSubmitSearch.to,
        companyId: taskSubmitSearch.companyId,
        jobTitles: taskSubmitSearch.jobTitles,
        timezone: dayjs().format(),
      };

      mutateExportExcel.mutate(payload);
    }
    }, [isShowCapchaModal]);
  return (
    <>
      <div className="mt-9 mb-20">
        <div className="flex justify-between">
          <SectionTitle name="Task Submission" />

          {isRoleAmin || isRoleSupperAdmin ? (
            buttonExportJSX()
          ) : (
            <ButtonA
              name="Start"
              icon={<StartIcon sx={{ marginRight: "5px" }} />}
              onHandleClick={() => {
                navigate("/create-task");
              }}
            />
          )}
        </div>
        {isRoleAmin || isRoleSupperAdmin ? (
          <TaskSubmissionAdminSearch
            taskSubmitSearch={taskSubmitSearch}
            setTaskSubmitSearch={setTaskSubmitSearch}
            setPage={setPage}
          />
        ) : (
          <TaskSubmissionSearch
            setTaskSubmitSearch={setTaskSubmitSearch}
            setPage={setPage}
          />
        )}

        <div className="mt-7">
          <TaskSubmissionList
            tasksSubmission={tasksSubmission}
            setTasksSubmission={setTasksSubmission}
            page={page}
            rowsPerPage={rowsPerPage}
            totalRecords={totalRecords}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>

      <NotifyExportModal
        isShowModal={isShowNotifyModal}
        setIsShowModal={setIsShowNotifyModal}
      />

      <CapchaTokenModal
        isShowModal={isShowCapchaModal}
        setIsShowModal={setIsShowCapchaModal}
        setCaptChaToken={setCaptChaToken as any}
        content={"Please verify you are human before export processing"}
      />
    </>
  );
};
