import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { EditButton } from "../../common/components/EditButton";

type EditCompanyDetailProps = {
  userId: string;
};

export const EditCompanyDetail: FC<EditCompanyDetailProps> = ({ userId }) => {
  const navigate = useNavigate();

  const onHandleEdit = (event: any) => {
    event.stopPropagation();
    navigate(`/update-company/${userId}`);
  };

  return <EditButton id={userId} onHandleEdit={onHandleEdit} />;
};
