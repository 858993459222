import { FC, useState } from "react";
import { ModalA } from "../../common/components/ModalA";
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { Asterisk } from "../../common/components/Asterisk";
import { Controller, useForm } from "react-hook-form";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import styled from "@emotion/styled";
import { downloadExcelTemplate } from "../miscellaneous/downloadExcelTemplate";
import { useMutation } from "react-query";
import { fetchImportUsers } from "../miscellaneous/fetchImportUsers";
import ReCAPTCHA from "react-google-recaptcha";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: "200px",
});

const formControlStyle = {
  marginTop: "20px",
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

type UsersModalProps = {
  companiesName: any;
  isShowImportModal: boolean;
  setIsShowImportModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ImportUsersModal: FC<UsersModalProps> = ({
  companiesName,
  isShowImportModal,
  setIsShowImportModal,
}) => {
  const [companies, setCompanies] = useState(companiesName);

  const [textFileName, setTextFileName] = useState("");
  const [isShowToastDanger, setIsShowToastDanger] = useState(false);
  const [isShowToastSuccess, setIsShowToastSuccess] = useState(false);

  const [captChaToken, setCaptChaToken] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm();

  const mutateImportUsers = useMutation(fetchImportUsers, {
    onSuccess: (response) => {
      if (response && response.status === 200) {
        setIsShowImportModal(false);
        setIsShowToastSuccess(true);
      }
    },
    onError: (error: AxiosError) => {
      if (error && error.response && error.response.status === 400) {
        setIsShowToastDanger(true);
      }
    },
  });

  const handleCloseModal = () => {
    setTextFileName("");
    setIsShowImportModal(false);
    reset();
  };

  function handleSetCapchaToken(value: string) {
    if (value) {
      setCaptChaToken(value as any);
    }
  };

  const handleSubmitImport = (data: any) => {

    if (!captChaToken) {

    } else {
      if (data.fileName && data.fileName[0]) {
        const payload = {
          companyId: data.companyId,
          file: data.fileName[0],
        };
        mutateImportUsers.mutate(payload);
      }
    }

  };

  const handleCloseToast = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsShowToastDanger(false);
  };

  const handleCloseToastSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsShowToastSuccess(false);
  };

  return (
    <>
      <ModalA
        title="Import form file"
        onCloseModal={handleCloseModal}
        isOpen={isShowImportModal}
      >
        <form onSubmit={handleSubmit(handleSubmitImport)}>
          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Organization <Asterisk />
            </label>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: "Organization is required" }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="companyId"
                  value={value ?? ""}
                  onChange={(e) => {
                    setValue("companyId", e.target.value);
                    clearErrors("companyId");
                  }}
                  style={{ height: "38px" }}
                >
                  {(companies ?? []).map((item: any) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("companyId").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Upload File <Asterisk />
            </label>

            <div className="flex justify-between">
              <TextField
                value={textFileName ?? ""}
                sx={{
                  input: {
                    height: "5px",
                  },
                }}
              />

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{
                  backgroundColor: "#203C77",
                  color: "#fff",
                  borderRadius: "5px",
                  minWidth: "100px",
                  textTransform: "capitalize",

                  "&:hover": {
                    backgroundColor: "#203C77",
                  },
                }}
              >
                Browser
                <VisuallyHiddenInput
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx"
                  {...register("fileName", {
                    required: "You must choose a file upload",
                  })}
                  onChange={(event) => {
                    if (event.target.files) {
                      setTextFileName(event.target.files[0].name);
                      clearErrors("fileName");
                    }
                  }}
                />
              </Button>
            </div>

            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("fileName").error?.message}
            </FormHelperText>

            <div className="text-sm italic mt-3">
              <p>The maximum file size allowed is 20MB.</p>
              <p>Maximum number of rows is 1000.</p>
              <p>
                Please use Microsoft Excel Version 2013 or later to enter the
                data to the Excel Template.
              </p>
              <p>Supported format: xls, xlxs.</p>
            </div>

            <div className="flex justify-start">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  downloadExcelTemplate();
                }}
                sx={{
                  textTransform: "capitalize",
                  color: "#203C77",
                  padding: 0,
                  marginTop: "5px",
                }}
              >
                Download Excel Template
              </Button>
            </div>

            <div className="text-center mt-3 mb-5 flex justify-center">
              <ReCAPTCHA
                sitekey="6LfA8espAAAAAG3fTbYTu_i2URP-TZT39vxDhIVK"
                onChange={handleSetCapchaToken as any}
              />
            </div>

          </FormControl>

          <FormControl fullWidth sx={btnGroupStyle}>
            <ButtonCancelA name="Cancel" onHandleClick={handleCloseModal} />
            <ButtonSubmitA name="Save" />
          </FormControl>
        </form>
      </ModalA>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isShowToastDanger}
        autoHideDuration={8000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <p>
            Cannot import users because the email address(s) already exist.
            Please make sure the email address is not in use.
          </p>
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isShowToastSuccess}
        autoHideDuration={8000}
        onClose={handleCloseToastSuccess}
      >
        <Alert
          onClose={handleCloseToastSuccess}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <p>Import users success</p>
        </Alert>
      </Snackbar>
    </>
  );
};
