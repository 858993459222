import { FC } from "react";
import { useMutation } from "react-query";
import _ from "lodash";
import { DeleteButton } from "../../common/components/DeleteButton";
import { useSetJobsState } from "../hooks/useSetJobsState";
import { useJobsValue } from "../hooks/useJobsValue";
import { fetchDeleteJobTitle } from "../miscellaneous/fetchDeleteJobTitle";

type DeleteJobProps = {
  id: string;
  info: string;
};

export const DeleteJobTitle: FC<DeleteJobProps> = ({ id, info }) => {
  const setJobs = useSetJobsState();
  const jobs = useJobsValue();

  const mutationDeleteJob = useMutation(fetchDeleteJobTitle, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const jobsFilter = _.filter(jobs, (item) => {
          return item.id !== id;
        });
        setJobs(jobsFilter);
      }
    },
    onError: () => {},
  });

  const handleDelete = (event: any) => {
    event.stopPropagation();
    mutationDeleteJob.mutate(id);
  };

  return (
    <DeleteButton
      id={id}
      title={"Delete Job Title"}
      info={info}
      onHandleDelete={handleDelete}
    />
  );
};
