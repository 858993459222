import { useMutation } from "react-query";
import { fetchGetDetailTaskSubmit } from "../miscellaneous/fetchGetDetailTaskSubmit";
import { useState } from "react";
import { fetchUpdateTaskSubmit } from "../miscellaneous/fetchUpdateTaskSubmit";
import { useNavigate } from "react-router-dom";
import { useSetOutcomeComment } from "./useSetOutcomeComment";

export const useUpdateTaskSubmit = (
  setIsShowToastSuccess: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const setOutcomeComment = useSetOutcomeComment();
  const [taskDetail, setTaskDetail] = useState<any>(null);
  const navigate = useNavigate();

  const mutateGetTaskDetail = useMutation(fetchGetDetailTaskSubmit, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setTaskDetail(res.data);
        setOutcomeComment(res.data.outcomeComment);
      }
    },
    onError: () => {},
  });

  const mutateUpdateTaskSubmission = useMutation(fetchUpdateTaskSubmit, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setIsShowToastSuccess(true);
      }
    },
    onError: () => {},
  });

  return {
    taskDetail,
    setTaskDetail,
    mutateGetTaskDetail,
    mutateUpdateTaskSubmission,
  };
};
