import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SectionTitle } from "../../common/components/SectionTitle";
import { Asterisk } from "../../common/components/Asterisk";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { ButtonA } from "../../common/components/ButtonA";
import { QuestionsModule } from "./QuestionsModule";
import { useCreateTaskSubmit } from "../hooks/useCreateTaskSubmit";
import { useTaskQuestionsValue } from "../hooks/useTaskQuestionsValue";
import { formatPayloadCreateTask } from "../miscellaneous/formatPayloadCreateTask";
import { textAreaStyle } from "../../tasks-list-management/styles/TaskTemplateFormStyle";
import { OutcomeTooltip } from "./OutcomeTooltip";
import { useOutcomeCommentValue } from "../hooks/useOutcomeCommentValue";
import { useSetOutcomeComment } from "../hooks/useSetOutcomeComment";

const textInputDisableStyle = {
  "& .MuiInputBase-input": {
    height: "5px",
    width: "100%",
  },

  "& .MuiInputBase-input.Mui-disabled": {
    backgroundColor: "#9f9f9f",
  },
};

const selectDisableStyle = {
  height: "38px",
  backgroundColor: "#9f9f9f",
};

const dateTimePickerStyle = {
  width: "70%",
  "& .MuiInputBase-input": {
    height: "5px",
  },
};

const taskNameDisabledStyle = {
  pointerEvents: "none",
  fontWeight: 600,
  color: "#FF0000"
};

export const CreateNewTask: FC = () => {
  const methods = useForm({});
  const navigate = useNavigate();
  const questionsValue = useTaskQuestionsValue();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    trigger,
    reset,
    formState: { errors },
  } = methods;

  const [jobTitle, setJobTitle] = useState(null as any);
  const [startedDate, setStartedDate] = useState<dayjs.Dayjs | null>(null);
  const [endedDate, setEndedDate] = useState<dayjs.Dayjs | null>(null);
  const [startSubmission, setStartSubmission] = useState<string>("");
  const [endSubmission, setEndSubmission] = useState<string>("");

  const [isShowToastSuccess, setIsShowToastSuccess] = useState(false);

  const [outcome, setOutcome] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const setOutcomeComment = useSetOutcomeComment();
  const outcomeComment = useOutcomeCommentValue();

  const [taskNameSelected, setTaskNameSelected] = useState({
    id: "",
    taskName: "",
  });

  const handleBackViewTasks = () => {
    navigate("/task-submission");
  };

  const handleCloseToast = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsShowToastSuccess(false);
  };

  const handleSubmitTask = (data: any) => {
    const outcomeValue = _.find(questionsValue ?? [], (item) => {
      return item.question.toLowerCase() === "outcome";
    });

    const formData = {
      ...data,
      starDate: startedDate?.format(),
      endDate: endedDate?.format(),
      startSubmission: startSubmission,
      endSubmission: endSubmission,
      jobTitleId: jobTitle.id ?? "",
      systemSlowSeconds: dayjs().format(),
      outcome: !!outcomeValue ? outcomeValue.value : "",
      outcomeComment:
        !!outcomeValue && outcomeValue.value === "other" ? outcomeComment : "",
    };

    const payload = formatPayloadCreateTask(formData, questionsValue);

    mutateCreateNewTaskSubmit.mutate(payload);
  };

  /** Handle suspend tasks */
  const handleSuspendTask = () => {
    if (!getValues("startDate")) {
      trigger("startDate");
      return;
    }

    if (!getValues("taskName")) {
      trigger("taskName");
      return;
    }

    if (!getValues("endDate")) {
      setValue("endDate", dayjs());
    }

    let questions = (questionsValue ?? []).map((item: any) => {
      return { id: item.id, value: item.value };
    });

    const isOutcomeQuestion = _.find(questionsValue ?? [], (item) => {
      return (
        item.question.toLowerCase() === "outcome" && item.questionTypeId === 2
      );
    });

    // Set default for outcome when hit suspend button.
    if (isOutcomeQuestion) {
      questions = (questionsValue ?? []).map((item: any) => {
        return item.question.toLowerCase() === "outcome" &&
          item.questionTypeId === 2
          ? { id: item.id, value: "suspend / interruption" }
          : { id: item.id, value: item.value };
      });
    }

    const payload = {
      startTime: getValues("startDate")?.format(),
      endTime: !!getValues("endDate")
        ? getValues("endDate")?.format()
        : dayjs()?.format(),
      taskTemplateId: getValues("taskName"),
      startSubmission: startSubmission,
      endSubmission: endSubmission,
      jobTitleId: jobTitle.id ?? "",
      taskSubmissionTime: dayjs().format(),
      systemSlowSeconds: dayjs().format(),
      taskSubmissionDetailDtos: questions,
      outcome: "suspend / interruption",
      outcomeComment: "",
    };

    mutateCreateNewTaskSubmit.mutate(payload);
  };

  const resetFrom = () => {
    reset({});
    resetFormState();
    resetFormValue();
    setSelectTaskDefault("");
    setTaskNameSelected({ id: "", taskName: "" });
  };

  const resetFormState = () => {
    setValue("startDate", null);
    setValue("endDate", null);
    setValue("taskName", null);
    setValue("outcome", "");
  };

  const resetFormValue = () => {
    setStartedDate(null);
    setEndedDate(null);
    setStartSubmission("");
    setEndSubmission("");
    setOutcome("");
    setComments("");
  };

  const {
    taskTemplate,
    outcomeQuestion,
    setTaskQuestions,
    mutationGetTaskName,
    mutateGetQuestionsByTask,
    mutateCreateNewTaskSubmit,
  } = useCreateTaskSubmit(setIsShowToastSuccess, resetFrom);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    mutationGetTaskName.mutate(companyId as string);

    const jobInfo = JSON.parse(localStorage.getItem("jobInfo") as string);
    setJobTitle({ id: jobInfo.Id, title: jobInfo.Title } as any);

    // Reset question list when begin.
    setTaskQuestions([]);
    setOutcomeComment("");
  }, []);

  const handleChangeStartDateTime = () => {
    setStartSubmission(dayjs().format());
    setStartedDate(dayjs());

    setValue("startDate", dayjs());
    clearErrors("startDate");
  };

  const handleChangeEndDateTime = () => {
    setEndSubmission(dayjs().format());
    setEndedDate(dayjs());

    setValue("endDate", dayjs());
    clearErrors("endDate");
  };

  const [selectTaskDefault, setSelectTaskDefault] = useState<string>(
    _.filter(taskTemplate ?? [], (item: any) => {
      return item.id === getValues("taskName");
    })[0]?.taskName ?? ""
  );

  return !!jobTitle ? (
    <div className="mt-9">
      <div className="flex flex-row justify-between">
        <SectionTitle name="Create a Task" />
        <ButtonCancelA
          name="View History"
          onHandleClick={handleBackViewTasks}
        />
      </div>

      <Box sx={{ minWidth: 120, marginTop: "60px", marginBottom: "200px" }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSubmitTask)}>
            <div className={"flex flex-row justify-between items-center"}>
              <span className="text-sub-title">Task Summary Information</span>
              <div className="flex flex-row gap-4">
                <ButtonCancelA
                  name="Suspend / Interruption"
                  onHandleClick={handleSuspendTask}
                />
                <ButtonSubmitA name="Submit" />
              </div>
            </div>

            <div className="grid grid-flow-col auto-cols-fr gap-8 pl-20 pr-20">
              <FormGroup>
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Task ID <Asterisk />
                  </label>
                  <TextField
                    defaultValue={""}
                    disabled
                    sx={textInputDisableStyle}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    <span>Start Time</span> <Asterisk />
                  </label>

                  <div className="flex flex-row justify-between">
                    <Controller
                      control={control}
                      name="startDate"
                      defaultValue={startedDate}
                      rules={{ required: "You must choose a start time" }}
                      render={({ field: { onChange } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            disableOpenPicker
                            onChange={(newValue: any) => {
                              onChange(newValue);
                              setStartedDate(newValue);
                              clearErrors("startDate");
                            }}
                            value={startedDate}
                            sx={dateTimePickerStyle}
                          />
                        </LocalizationProvider>
                      )}
                    ></Controller>
                    <ButtonA
                      name="Set value"
                      onHandleClick={handleChangeStartDateTime}
                    />
                  </div>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("startDate").error?.message}
                  </FormHelperText>
                </FormControl>
              </FormGroup>

              <FormGroup>
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Select Role <Asterisk />
                  </label>
                  <Select
                    id="user-job-title"
                    disabled
                    value={jobTitle.id ?? ""}
                    style={selectDisableStyle}
                  >
                    <MenuItem value={jobTitle.id ?? ""}>
                      {jobTitle.title ?? ""}
                    </MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </div>

            <div className={"mt-10"}>
              <span className="text-sub-title">Task Detail</span>

              <div className="pl-20 pr-20">
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Select Task <Asterisk />
                  </label>
                  <Controller
                    control={control}
                    name="taskName"
                    rules={{ required: "Task Name is required" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        disablePortal
                        id="taskName"
                        options={taskTemplate ?? []}
                        getOptionLabel={(option: any) => option.taskName}
                        value={taskNameSelected}
                        onChange={(event, data, reason) => {
                          if (reason === "clear") {
                            setSelectTaskDefault("");
                          }

                          if (!!data) {
                            setSelectTaskDefault(data.taskName);
                            setValue("taskName", data.id);
                            clearErrors("taskName");

                            setTaskNameSelected(
                              _.find(taskTemplate ?? [], (item: any) => {
                                return item.id === data.id;
                              })
                            );

                            mutateGetQuestionsByTask.mutate(data.id);
                          } else {
                            setValue("taskName", null);
                          }
                        }}
                        renderOption={(props, option: any, { selected }) => {
                          return (
                            <li
                              key={option.id}
                              value={option.id}
                              {...props}
                              style={
                                option.taskType === "Label"
                                  ? (taskNameDisabledStyle as any)
                                  : {}
                              }
                            >
                              {option.taskName}
                            </li>
                          );
                        }}
                        inputValue={selectTaskDefault}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              onChange={(event) => {
                                setSelectTaskDefault(event.target.value);
                              }}
                              sx={{
                                "& .MuiInputBase-input": {
                                  height: "5px",
                                },
                              }}
                            />
                          );
                        }}
                      />

                      // <Select
                      //   id="taskName"
                      //   value={value ?? ""}
                      //   onChange={(e) => {
                      //     setValue("taskName", e.target.value);
                      //     clearErrors("taskName");

                      //     mutateGetQuestionsByTask.mutate(e.target.value);
                      //   }}
                      //   style={{ height: "38px" }}
                      // >
                      //   {(taskTemplate ?? []).map((item: any) => {
                      //     return (
                      //       <MenuItem key={item.id} value={item.id}>
                      //         {item.taskName}
                      //       </MenuItem>
                      //     );
                      //   })}
                      // </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("taskName").error?.message}
                  </FormHelperText>
                </FormControl>

                {/* <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Outcome <Asterisk /> <OutcomeTooltip />
                  </label>
                  <Controller
                    control={control}
                    name="outcome"
                    defaultValue={""}
                    rules={{ required: "Outcome is required" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        id="outcome"
                        value={value ?? ""}
                        onChange={(e) => {
                          setValue("outcome", e.target.value);
                          setOutcome(e.target.value);
                          clearErrors("outcome");
                        }}
                        style={{ height: "38px" }}
                      >
                        <MenuItem key={"suspend"} value={"suspend"}>
                          Suspend
                        </MenuItem>
                        <MenuItem
                          key={"suspend / interruption"}
                          value={"suspend / interruption"}
                        >
                          Suspend / Interruption
                        </MenuItem>
                        <MenuItem key={"completed"} value={"completed"}>
                          Completed
                        </MenuItem>
                        <MenuItem
                          key={"completed / no action taken"}
                          value={"completed / no action taken"}
                        >
                          Completed / No Action Taken
                        </MenuItem>
                        <MenuItem key={"delete"} value={"delete"}>
                          Delete
                        </MenuItem>
                        <MenuItem
                          key={"external delay"}
                          value={"external delay"}
                        >
                          External Delay
                        </MenuItem>
                        <MenuItem
                          key={"external delay - nigo"}
                          value={"external delay - nigo"}
                        >
                          External Delay - NIGO
                        </MenuItem>
                        <MenuItem
                          key={"internal delay"}
                          value={"internal delay"}
                        >
                          Internal Delay
                        </MenuItem>
                        <MenuItem
                          key={"review only - no action taken"}
                          value={"review only - no action taken"}
                        >
                          Review Only - No Action Taken
                        </MenuItem>
                        <MenuItem key={"other"} value={"other"}>
                          Other
                        </MenuItem>
                      </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("outcome").error?.message}
                  </FormHelperText>
                </FormControl> */}

                {/* {outcome === "other" ? (
                  <FormControl fullWidth sx={{ marginTop: "23px" }}>
                    <label className="font-bold">Comments</label>
                    <TextareaAutosize
                      placeholder=""
                      value={comments ?? ""}
                      minRows={3}
                      onChange={(event) => {
                        setComments(event.target.value);
                      }}
                      style={textAreaStyle}
                    />
                  </FormControl>
                ) : null} */}

                <QuestionsModule />

                <FormControl sx={{ marginTop: "23px", width: "48%" }}>
                  <label className="font-bold">
                    <span>End Time</span> <Asterisk />
                  </label>
                  <div className="flex flex-row justify-between">
                    <Controller
                      control={control}
                      name="endDate"
                      defaultValue={endedDate}
                      rules={{ required: "You must choose a end time" }}
                      render={({ field: { onChange } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            disableOpenPicker
                            onChange={(newValue: any) => {
                              onChange(newValue);
                              setEndedDate(newValue);
                            }}
                            value={endedDate}
                            sx={dateTimePickerStyle}
                          />
                        </LocalizationProvider>
                      )}
                    ></Controller>
                    <ButtonA
                      name="Set value"
                      onHandleClick={handleChangeEndDateTime}
                    />
                  </div>
                </FormControl>
                <FormHelperText sx={{ color: "red" }}>
                  {control.getFieldState("endDate").error?.message}
                </FormHelperText>
              </div>
            </div>

            <div className="flex flex-row justify-center mt-10 mp-10 gap-4">
              <ButtonCancelA
                name="Suspend / Interruption"
                onHandleClick={handleSuspendTask}
              />
              <ButtonSubmitA name="Submit" />
            </div>
          </form>
        </FormProvider>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isShowToastSuccess}
        autoHideDuration={5000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Create a new task success
        </Alert>
      </Snackbar>
    </div>
  ) : null;
};
