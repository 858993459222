import { FC, useEffect, useState } from "react";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { MuiChipsInput } from "mui-chips-input";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Asterisk } from "../../common/components/Asterisk";
import { SectionTitle } from "../../common/components/SectionTitle";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { fetchUpdateCompany } from "../miscellaneous/fetchUpdateCompany";
import { fetchGetCompany } from "../miscellaneous/fetchGetCompany";

const formControlStyle = {
  marginTop: "20px",
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

export const UpdateCompanyInfo: FC = () => {
  const { companyId } = useParams();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const [chips, setChips] = useState([]);
  const [companyDetail, setCompanyDetail] = useState(null as any);

  const [isShowToastDanger, setIsShowToastDanger] = useState(false);

  const handleCloseToast = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsShowToastDanger(false);
  };

  const mutateGetUser = useMutation(fetchGetCompany, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setCompanyDetail({
          companyName: res.data.companyName,
          jobTitles: _.map(res.data.jobs, "title"), // res.data.jobs.map(),
          status: res.data.isActive ? "active" : "inactive",
        });
        setChips(_.map(res.data.jobs, "title") as any);
      }
    },
    onError: () => {},
  });

  const mutateUpdateCompany = useMutation(fetchUpdateCompany, {
    onSuccess: (res) => {
      if (res.status === 200) {
        navigate("/company-management");
      }
    },
    onError: (error: AxiosError) => {
      if (
        error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data === "Cannot delete in use job title(s)"
      ) {
        setIsShowToastDanger(true);
        // Must get value of job title when update failed.
        setChips(companyDetail.jobTitles);
      }
    },
  });

  useEffect(() => {
    mutateGetUser.mutate(companyId as string);
  }, []);

  const onSubmit = (data: any) => {
    const payload = {
      id: companyId,
      companyName: data.companyName ?? "",
      jobTitles: chips ?? [],
      isActive: data.status === "active" ? true : false,
    };

    mutateUpdateCompany.mutate(payload);
  };

  const handleChange = (newChips: any) => {
    setChips(newChips);
  };

  const handleBackToCompanies = () => {
    navigate("/company-management");
  };

  return !!companyDetail ? (
    <div className="mt-9">
      <SectionTitle name="Update Company Detail" />
      <Box sx={{ minWidth: 120, marginTop: "60px" }}>
        <form className="pr-24 pl-24 pb-24" onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Organization <Asterisk />
            </label>
            <TextField
              {...register("companyName", {
                required: "Organization is required",
              })}
              defaultValue={companyDetail.companyName}
              sx={{
                "& .MuiInputBase-input": {
                  height: "5px",
                },
              }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("companyName").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">Job Title</label>
            <MuiChipsInput value={chips} onChange={handleChange} />
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">Status</label>
            <Controller
              control={control}
              name="status"
              defaultValue={companyDetail.status ?? "active"}
              rules={{ required: "Status is required" }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="status"
                  value={value ?? "active"}
                  onChange={(e) => {
                    setValue("status", e.target.value);
                    clearErrors("status");
                  }}
                  style={{ height: "38px" }}
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("status").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={btnGroupStyle}>
            <ButtonCancelA
              name="Cancel"
              onHandleClick={handleBackToCompanies}
              icon={<CancelIcon sx={{ marginRight: "4px" }} />}
            />
            <ButtonSubmitA
              name="Save"
              icon={<SaveIcon sx={{ marginRight: "4px" }} />}
            />
          </FormControl>
        </form>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isShowToastDanger}
        autoHideDuration={5000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <p>
            Cannot delete the selected job title(s) because it is in use. Please
            make sure the job title is not associated with any existing user.
          </p>
        </Alert>
      </Snackbar>
    </div>
  ) : null;
};
