import { FC } from "react";
import { useMutation } from "react-query";
import { useUsersValueDate } from "../hooks/useUsersValueData";
import { useSetUsersState } from "../hooks/useSetUsersState";
import { fetchDeleteUser } from "../miscellaneous/fetchDeleteUser";
import { DeleteButton } from "../../common/components/DeleteButton";

type UserDeleteProps = {
  userId: string;
  userName: string;
};

export const UserDetailDelete: FC<UserDeleteProps> = ({ userId, userName }) => {
  const setUsers = useSetUsersState();
  const usersValue = useUsersValueDate();

  const mutationDeleteUser = useMutation(fetchDeleteUser, {
    onSuccess: () => {
      const usersFilter = usersValue.filter((item: any) => {
        return item.id !== userId;
      });

      setUsers(usersFilter);
    },
    onError: () => {},
  });

  const handleDeleteUser = () => {
    mutationDeleteUser.mutate(userId);
  };

  return (
    <DeleteButton
      id={userId}
      title={"Delete User"}
      info={userName}
      onHandleDelete={handleDeleteUser}
    />
  );
};
