import axios from "axios";

export const fetchQueryUsers = async (data: any) => {
  const path = "/api/user";
  const param = `?companyName=${data.companyName ?? ""}&role=${data.role ?? ""}&fullName=${data.fullName ?? ""}`;
  const paging = `&pageSize=${data.pageSize}&pageNumber=${data.pageNumber}`;
  const url = `${path}${param}${paging}`;

  return await axios.get(url);
};
