import axios from "axios";

export const fetchUpdateCompany = async (data: any) => {
  const body = {
    companyName: data.companyName,
    jobTitles: data.jobTitles,
    isActive: data.isActive,
  };

  return await axios.post(`/api/company/${data.id}`, body);
};
