import { useState } from "react";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import { fetchGetAllTasksSubmit } from "../miscellaneous/fetchGetAllTasksSubmit";
import { fetchExportSubmission } from "../miscellaneous/fetchExportSubmission";

export const useGetTaskSubmission = (
  setIsShowNotifyModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [tasksSubmission, setTasksSubmission] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const mutateGetAllTaskSubmissison = useMutation(fetchGetAllTasksSubmit, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setTasksSubmission(res.data.results);
        setTotalRecords(res.data.totalRecords);
      }
    },
    onError: () => {},
  });

  const mutateExportExcel = useMutation(fetchExportSubmission, {
    onSuccess: (res) => {
      if (res.status === 200) {
        // const fileName = `Momentus_Reporting_[${dayjs()}].xlsx`;

        // const url = window.URL.createObjectURL(res.data);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = fileName;
        // document.body.appendChild(link);
        // link.click();

        // window.URL.revokeObjectURL(url);

        setIsShowNotifyModal(true);
      }
    },
    onError: () => {},
  });

  return {
    tasksSubmission,
    setTasksSubmission,
    totalRecords,
    setTotalRecords,
    mutateGetAllTaskSubmissison,
    mutateExportExcel,
  };
};
