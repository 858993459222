import { FC } from "react";
import { Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const searchBtnStyle = {
  backgroundColor: "#203C77",
  color: "#fff",
  fontSize: "14px",
  width: "100px",
  borderRadius: "50px",
  textTransform: "capitalize",

  "&:hover": {
    backgroundColor: "#203C77",
  },
};

export const SearchButton: FC = () => {
  return (
    <Button sx={searchBtnStyle} type="submit">
      <SearchIcon /> Search
    </Button>
  );
};
