import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import _ from "lodash";
import { fetchGetAllCompanies } from "../../organization-management/miscellaneous/fetchGetAllCompanies";
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { SearchButton } from "../../common/components/SearchButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { fetchGetJobsByCompanyId } from "../../jobs-management/miscellaneous/fetchGetJobsByCompanyId";
import dayjs from "dayjs";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SearchTasksProps = {
  taskSubmitSearch: any;
  setTaskSubmitSearch: any;
  setPage: any;
};

export const TaskSubmissionAdminSearch: FC<SearchTasksProps> = ({
  taskSubmitSearch,
  setTaskSubmitSearch,
  setPage
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm();

  const [companiesName, setCompaniesName] = useState([]);
  //   const [chips, setChips] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [jobTitle, setJobTitle] = useState([]);

  const [jobTitleSelected, setJobTitleSelected] = useState([]);

  const mutationGetJobsByCompany = useMutation(fetchGetJobsByCompanyId, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const jobs = _.map(res.data.data ?? [], (item) => {
          return { id: item.id, title: item.title };
        });

        setJobTitle(jobs as any);
        setJobTitleSelected([]);
      }
    },
    onError: () => {},
  });

  const mutationGetCompanies = useMutation(fetchGetAllCompanies, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyName = _.map(res.data.data, (item) => {
          return { name: item.companyName, value: item.id };
        });
        setCompaniesName(companyName as any);
      }
    },
    onError: () => {},
  });

  const handleReset = () => {
    setPage(0);
    reset();
    setJobTitle([]);
    setFromDate(null);
    setToDate(null);

    setJobTitleSelected([]);
  };

  const onSearchSubmit = (data: any) => {
    setPage(0);
    const jobTitleIds = _.map(data.jobTitle ?? [], "id");

    const currentTime = dayjs().format().substring(dayjs().format().lastIndexOf("T") + 1, dayjs().format().length);

    const fromTime = !!fromDate
      ? `${(fromDate as any).format().substring(0, dayjs().format().lastIndexOf("T") + 1)}${currentTime}`
      : null;
    const toTime = !!toDate
      ? `${(toDate as any).format().substring(0, dayjs().format().lastIndexOf("T") + 1)}${currentTime}`
      : null;

    const newPayload = {
      ...taskSubmitSearch,
      companyId: data.organization,
      jobTitles: jobTitleIds,
      from: !!fromDate ? fromTime : null,
      to: !!toDate ? toTime : null,
    };

    // taskSubmitSearch
    setTaskSubmitSearch(newPayload);
  };

  useEffect(() => {
    mutationGetCompanies.mutate();
  }, []);

  return (
    <div className="mt-9 border-2 pt-7 pb-7">
      <form onSubmit={handleSubmit(onSearchSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col items-center">
            <div className="flex justify-between items-center mt-5">
              <label className="font-bold w-24">Organization</label>
              <Controller
                control={control}
                name="organization"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="organization"
                    value={value ?? ""}
                    onChange={(e) => {
                      setValue("organization", e.target.value);
                      mutationGetJobsByCompany.mutate(e.target.value);
                    }}
                    style={{ height: "55px", width: "300px" }}
                  >
                    {(companiesName ?? []).map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              ></Controller>
            </div>
            <div className="flex justify-between items-center mt-5">
              <label className="font-bold w-24">Job Title</label>
              <Controller
                control={control}
                name="jobTitle"
                defaultValue={jobTitle ?? []}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    multiple
                    id="checkboxes-tags"
                    options={jobTitle ?? []}
                    value={jobTitleSelected ?? []}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.title}
                    isOptionEqualToValue={(option: any, value) =>
                      value === undefined || option.id === value.id
                    }
                    onChange={(event, data) => {
                      setValue("jobTitle", data);
                      setJobTitleSelected(data as any);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "300px" }} />
                    )}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-between">
            <div className="flex justify-between items-center w-80 mt-5">
              <label className="font-bold w-16">From</label>
              <Controller
                name="selectedDate"
                control={control}
                defaultValue={fromDate}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue as any);
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "20px",
                          width: "300px",
                        },
                      }}
                      // sx={{ height: "283px" }}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
            <div className="flex justify-between items-center w-80 mt-5">
              <label className="font-bold w-16">To</label>
              <Controller
                name="selectedDate"
                control={control}
                defaultValue={toDate}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue as any);
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "20px",
                          width: "300px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-center justify-center mt-10 gap-5">
          <ButtonCancelA
            name="Reset"
            onHandleClick={handleReset}
            icon={<ClearIcon />}
          />
          <SearchButton />
        </div>
      </form>
    </div>
  );
};
