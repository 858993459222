import { FC } from "react";
import { Checkbox } from "@mui/material";

type TableHeadCheckboxProps = {
  selected: readonly (number | string)[];
  data: any;
  onHandleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TableHeadCheckbox: FC<TableHeadCheckboxProps> = ({
  data,
  selected,
  onHandleSelectAllClick,
}) => {
  return (
    <Checkbox
      color="primary"
      indeterminate={false}
      checked={selected.length === data.length}
      onChange={onHandleSelectAllClick}
      inputProps={{
        "aria-label": "select all desserts",
      }}
      sx={{
        color: "#203C77",
        "&.Mui-checked": {
          color: "#203C77",
        },
      }}
    />
  );
};
