import { FC } from "react";

export const PageNotFound: FC = () => {
  return (
    <main className="min-h-screen flex flex-col items-center mt-32 text-3xl main-button-color">
      <p className="text-5xl mb-6 font-bold">404</p>
      <p>Oops! Page Not Found</p>
    </main>
  );
};
