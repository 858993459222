import { FC } from "react";
import { UserDetailEdit } from "./UserDetailEdit";
import { UserDetailDelete } from "./UserDetailDelete";

type UserActionsProps = {
  userId: string;
  username: string;
};

export const UserDetailActions: FC<UserActionsProps> = ({
  userId,
  username,
}) => {
  return (
    <div>
      <UserDetailEdit userId={userId} />
      <UserDetailDelete userId={userId} userName={username} />
    </div>
  );
};
