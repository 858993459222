import { FC } from "react";
import { Checkbox } from "@mui/material";

type TableCellCheckboxProps = {
  isItemSelected: boolean;
};

export const TableCellCheckbox: FC<TableCellCheckboxProps> = ({
  isItemSelected,
}) => {
  return (
    <Checkbox
      color="primary"
      checked={isItemSelected}
      inputProps={{
        "aria-labelledby": "none",
      }}
      sx={{
        color: "#203C77",
        "&.Mui-checked": {
          color: "#203C77",
        },
      }}
    />
  );
};
