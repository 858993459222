import { Button } from "@mui/material";
import { FC } from "react";

type PropsButtonCancelA = {
  name: string;
  onHandleClick: () => void;
  icon?: any;
};

const ButtonCancelStyle = {
  backgroundColor: "#fff",
  color: "#203C77",
  fontSize: "14px",
  fontWeight: "100",
  textTransform: "capitalize",
  borderRadius: "50px",
  border: "2px solid",
  boxSizing: "border-box",
  minWidth: "100px",

  "&:hover": {
    backgroundColor: "#fff",
  },
};

export const ButtonCancelA: FC<PropsButtonCancelA> = ({ name, onHandleClick, icon }) => {
  return (
    <Button type="button" size={"medium"} sx={ButtonCancelStyle} onClick={onHandleClick}>
      {icon}{name}
    </Button>
  );
};
