import Tooltip from "@mui/joy/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

export const OutcomeTooltip = () => {
  return (
    <Tooltip
      title={
        <div className="grid grid-flow-col auto-cols-2 gap-5 p-5 leading-6 tooltip-text-color">
          <div>
            <label className="font-semibold">Outcome</label>
            <p>Suspend</p>
            <p>Suspend / Interruption</p>
            <p>Completed</p>
            <p>Completed / No Action Taken</p>
            <p>Delete</p>
            <p>External Delay</p>
            <p>External Delay - NIGO</p>
            <p>Internal Delay</p>
            <p>Review Only</p>
            <p>Other</p>
          </div>
          <div>
            <label className="font-semibold">Definition</label>
            <p>
              Need to put the task down for reasons other than the content of
              the task - e.g., go to lunch, a decision you made to set it down
            </p>
            <p>
              Chat, email or call that takes you away from the current task
              (done by hitting the "suspend/interruption" button near the
              "submit" button)
            </p>
            <p>
              Task completed (only used for tasks that were fully completed,
              where you don't expect to receive the task back to finish)
            </p>
            <p>No work needed in order to complete this task</p>
            <p>Request or diary created in error</p>
            <p>Waiting for client response – part of normal procedure</p>
            <p>
              Unable to complete the task due to missing information – not part
              of normal procedure
            </p>
            <p>
              Waiting for Internal Diary or Future effective date before my task
              will come back to me to complete – often part of normal procedure
            </p>
            <p>
              No Action Taken Misdirected request that is simply rejected or
              sent back to the requestor
            </p>
            <p>Provide comments</p>
          </div>
        </div>
      }
      placement="top-end"
      sx={{
        backgroundColor: "#fff",
        border: "1.2px gray solid",
        boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
        color: "#fff",
        "& .MuiTooltip-arrow::before": {
          color: "#fff",
          backgroundColor: "#fff",
          borderTopColor: "#fff",
          borderRight: "1.2px gray solid",
        },
      }}
    >
      <span className="w-1">
        <InfoIcon sx={{ fontSize: "18px", cursor: "pointer" }} />
      </span>
    </Tooltip>
  );
};
