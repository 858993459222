import { FC, useState } from "react";
import _ from "lodash";
import { ModalA } from "../../common/components/ModalA";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { ButtonA } from "../../common/components/ButtonA";
import { Asterisk } from "../../common/components/Asterisk";
import { fetchGetTemplateByCompanyId } from "../miscellaneous/fetchGetTemplateByCompanyId";
import { fetchGetTaskDetail } from "../miscellaneous/fetchGetTaskDetail";
import { TaskTemplateWithIdType } from "../types/TaskTemplateType";

type CopyExistsProps = {
  isShowBox: boolean;
  setIsShowBox: any;
  companiesName: any[];
  onHandleApplyCopy: any;
};

const cancelBtnStyle = {
  backgroundColor: "#fff",
  color: "#172952",
  width: "100px",
  fontWeight: 600,
  border: "1.5px solid",
  borderRadius: "50px",
  textTransform: "capitalize"
};

export const CopyFromExistsTemplate: FC<CopyExistsProps> = ({
  isShowBox,
  setIsShowBox,
  companiesName,
  onHandleApplyCopy,
}) => {
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm();

  const [templates, setTemplates] = useState([]);

  const handleCloseModal = () => {
    setIsShowBox(false);
  };

  const mutationGetDetail = useMutation(fetchGetTaskDetail, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const templateDetailWithIds = (
          res.data.taskTemplateDetailDtos ?? []
        ).map((item: TaskTemplateWithIdType) => {
          return { ...item, id: uuidv4() };
        });

        const task = {
          ...res.data,
          taskTemplateDetailDtos: templateDetailWithIds,
        };
        onHandleApplyCopy(task);
      }
    },
    onError: () => {},
  });

  const mutateGetTemplateByCompanyId = useMutation(
    fetchGetTemplateByCompanyId,
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          const result = _.map(res.data.taskTemplateDtos ?? [], (item: any) => {
            return { id: item.id, taskName: item.taskName };
          });

          setTemplates(result as any);
        }
      },
      onError: () => {},
    }
  );

  const handleSelect = () => {
    const taskName = getValues("taskName");

    if (!taskName) {
      trigger();
    } else {
      mutationGetDetail.mutate(taskName as string);
      setIsShowBox(false);
    }
  };

  return (
    <ModalA
      title={"Select a Template"}
      isOpen={isShowBox}
      onCloseModal={handleCloseModal}
    >
      <Box component="section" sx={{ p: 2 }}>
        <div>
          <form>
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <label className="font-bold">
                Organization <Asterisk />
              </label>
              <Controller
                control={control}
                name="organization"
                rules={{ required: "Organization is required" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="organization"
                    value={value ?? ""}
                    onChange={(e) => {
                      setValue("organization", e.target.value);
                      clearErrors("organization");

                      mutateGetTemplateByCompanyId.mutate(e.target.value);
                    }}
                    style={{ height: "38px" }}
                  >
                    {(companiesName ?? []).map((item: any) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              ></Controller>
              <FormHelperText sx={{ color: "red" }}>
                {control.getFieldState("organization").error?.message}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <label className="font-bold">
                Task Name <Asterisk />
              </label>
              <Controller
                control={control}
                name="taskName"
                rules={{ required: "Task Name is required" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="taskName"
                    value={value ?? ""}
                    onChange={(e) => {
                      setValue("taskName", e.target.value);
                      clearErrors("taskName");
                    }}
                    style={{ height: "38px" }}
                  >
                    {(templates ?? []).map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.taskName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              ></Controller>
              <FormHelperText sx={{ color: "red" }}>
                {control.getFieldState("taskName").error?.message}
              </FormHelperText>
            </FormControl>

            <div className="flex flex-row mt-5 justify-center gap-3">
              <ButtonA
                name="Cancel"
                customStyle={cancelBtnStyle}
                onHandleClick={handleCloseModal}
              />

              <ButtonA name="Select" onHandleClick={handleSelect} />
            </div>
          </form>
        </div>
      </Box>
    </ModalA>
  );
};
