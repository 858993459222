export function parseCookie(cookieString: string) {
  return cookieString === ""
    ? {}
    : cookieString
        .split(";")
        .map((subString) => subString.split("="))
        .reduce((accumulator: any, [key, value]: any) => {
          const checkKey = decodeURIComponent(key.trim());

          accumulator[checkKey] = decodeURIComponent(value.trim());

          return accumulator;
        }, {});
}

export const isExistsLogin = (): boolean => {

  if (!parseCookie(document.cookie)["loginToken"]) {
    return false;
  }

  const loginCookie = JSON.parse(parseCookie(document.cookie)["loginToken"]);

  return loginCookie && !!loginCookie.access_token ? true : false;
};

export const cookieInfo = (cookieName: string) => {
    const cookieValue = parseCookie(document.cookie)[cookieName];
    if (cookieValue) {
        return JSON.parse(cookieValue);
    }
  
    return null;
};

export const deleteCookie = (cookieName: string) => {
  const isLocalHost = window.location && window.location.host.includes("localhost");
      const domainToken = isLocalHost ? "localhost" : `.etptracker.com`;
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domainToken}; path=/;`;
};
