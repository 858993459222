import { FC, ReactNode } from "react";

type Props = {
  readonly children: ReactNode;
  readonly className?: string;
};

export const Container: FC<Props> = ({ children }) => {
  return (
    <div className="w-11/12 max-w-6xl mr-auto ml-auto">
      <div className="section-layout">{children}</div>
    </div>
  );
};
