import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { fetchGetAllCompanies } from "../../organization-management/miscellaneous/fetchGetAllCompanies";
import { fetchGetQuestionsType } from "../miscellaneous/fetchGetQuestionsType";
import { fetchGetTasksType } from "../miscellaneous/fetchGetTasksType";
import { fetchCreateTaskTemplate } from "../miscellaneous/fetchCreateTaskTemplate";
import { fetchGetJobsByCompanyId } from "../../jobs-management/miscellaneous/fetchGetJobsByCompanyId";

export const useCreateTemplate = () => {
  const [companiesName, setCompaniesName] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [questionsType, setQuestionType] = useState([]);
  const [tasksType, setTasksType] = useState([]);

  const navigate = useNavigate();

  const mutationGetCompanies = useMutation(fetchGetAllCompanies, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyName = _.map(res.data.data, (item) => {
          return { name: item.companyName, value: item.id };
        });
        setCompaniesName(companyName as any);
      }
    },
    onError: () => {},
  });

  const mutationGetQuestionType = useMutation(fetchGetQuestionsType, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setQuestionType(res.data);
      }
    },
    onError: () => {},
  });

  const mutationGetTaskType = useMutation(fetchGetTasksType, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setTasksType(res.data);
      }
    },
    onError: () => {},
  });

  const mutationCreateTemplate = useMutation(fetchCreateTaskTemplate, {
    onSuccess: (res) => {
      if (res.status === 200) {
        navigate("/tasks-list-management");
      }
    },
    onError: () => {},
  });

  const mutationGetJobsByCompany = useMutation(fetchGetJobsByCompanyId, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const jobs = _.map(res.data.data ?? [], (item) => {
          return { id: item.id, title: item.title };
        });

        setJobTitle(jobs as any);
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    mutationGetCompanies.mutate();
    mutationGetQuestionType.mutate();
    mutationGetTaskType.mutate();
  }, []);

  return {
    mutationCreateTemplate,
    mutationGetJobsByCompany,
    companiesName,
    jobTitle,
    tasksType,
    questionsType,
  };
};
