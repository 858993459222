import { FC, useState } from "react";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import { TextInput } from "../../common/components/TextInput";
import { SearchButton } from "../../common/components/SearchButton";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { DatePickerInput } from "../../common/components/DatePickerInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputLabel } from "@mui/material";

type TaskSubmitSearchProps = {
  setTaskSubmitSearch: any;
  setPage: any;
};

export const TaskSubmissionSearch: FC<TaskSubmitSearchProps> = ({
  setTaskSubmitSearch, setPage
}) => {
  const {
    reset,
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [startDate, setStartDate] = useState(null);

  const onSubmit = (data: any) => {
    setTaskSubmitSearch({
      taskId: !!data.taskId ? data.taskId : 0,
      taskName: !!data.taskName ? data.taskName : "",
      dateCompleted: !!startDate
        ? (startDate as any).format("YYYY-MM-DD")
        : null,
    });
    setPage(0);
  };

  const handleResetForm = () => {
    reset({
      taskId: "",
      taskName: ""
    });
    setStartDate(null);
    setPage(0);
  };

  return (
    <div className="mt-9 border-2 pt-7 pb-7">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-center">
          <div className="m-0 mx-5">
            <TextInput
              label={"Task ID"}
              value=""
              register={register("taskId")}
            />
          </div>

          <div className="m-0 mx-5">
            <TextInput
              label={"Task Name"}
              value=""
              register={register("taskName")}
            />
          </div>

          <div className="m-0 mx-5">
            <Controller
              name="selectedDate"
              control={control}
              defaultValue={startDate}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="flex flex-row items-center">
                    <label className="font-bold mr-3">Date Completed</label>
                    <DatePicker
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue as any);
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "5px",
                        },
                      }}
                    />
                  </div>
                </LocalizationProvider>
              )}
            />
          </div>
        </div>

        <div className="flex flex-center justify-center mt-10 gap-5">
          <ButtonCancelA
            name="Reset"
            onHandleClick={() => {
              handleResetForm();
            }}
            icon={<ClearIcon />}
          />
          <SearchButton />
        </div>
      </form>
    </div>
  );
};
