import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import CreateIcon from "@mui/icons-material/Create";
import { UserManagementTable } from "./UserManagementTable";
import { UserManagementSearch } from "./UserManagementSearch";
import { ButtonA } from "../../common/components/ButtonA";
import { SectionTitle } from "../../common/components/SectionTitle";
import { usePaginationTable } from "../../common/hooks/usePaginationTable";
import { useSetUsersState } from "../hooks/useSetUsersState";
import { useUsersValueDate } from "../hooks/useUsersValueData";
import { fetchQueryUsers } from "../miscellaneous/fetchQueryUsers";

export const UserManagement: FC = () => {
  const navigate = useNavigate();
  const setUsers = useSetUsersState();
  const users = useUsersValueDate();

  const {
    page,
    rowsPerPage,
    emptyRows,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
  } = usePaginationTable(users);
  const [totalRecords, setTotalRecords] = useState(0);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm();

  const [userSearch, setUserSearch] = useState({
    companyName: "",
    fullName: "",
    role: "",
  });

  const mutationQueryUsers = useMutation(fetchQueryUsers, {
    onSuccess: (response) => {
      if (response && response.status === 200) {
        setUsers(response.data.data);
        setTotalRecords(response.data.totalRecords);
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    const queryParam = {
      pageSize: rowsPerPage,
      pageNumber: page,
      companyName: userSearch.companyName,
      fullName: userSearch.fullName,
      role: userSearch.role,
    };

    mutationQueryUsers.mutate(queryParam);
  }, [page, rowsPerPage, JSON.stringify(userSearch)]);

  return (
    <div className="mt-9">
      <div className="flex justify-between">
        <SectionTitle name="User Management" />
        <ButtonA
          name="Create"
          icon={<CreateIcon />}
          onHandleClick={() => {
            navigate("/create-new-user");
          }}
        />
      </div>
      <div>
        <UserManagementSearch
          control={control}
          register={register}
          handleSubmit={handleSubmit}
          setValue={setValue}
          getValues={getValues}
          reset={reset}
          setUserSearch={setUserSearch}
          setPage={setPage}
        />
      </div>
      <div className="mt-7 mb-12">
        <UserManagementTable
          isLoading={mutationQueryUsers.isLoading}
          page={page}
          rowsPerPage={rowsPerPage}
          emptyRows={emptyRows}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};
