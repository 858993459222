import { isExistsLogin } from "../../common/miscellaneous/handleCookies";
import { SignIn } from "../../sign-in/components/SignIn";
import SidebarImage from "../images/HomeImgSection2.png";

export function MainSection() {
  return (
    <section className="pt-28 pb-12">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <div className="mt-8">
            <p className="section-home-title">Momentus</p>
            <p className="text-6xl"></p>
            {isExistsLogin() ? null : (
              <p className="text-base mt-4">
                Please login to experience this application
              </p>
            )}
          </div>
          <div className="mt-12">{isExistsLogin() ? null : <SignIn />}</div>
        </div>

        <div id="sidebar-image" className="flex justify-end">
          <img src={SidebarImage} alt="" className="w-5/6 rounded-3xl" />
        </div>
      </div>
    </section>
  );
}
