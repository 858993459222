import _ from "lodash";

export const sortQuestionsTemplate = (arr: any) => {
  const outcome = _.find(arr ?? [], (question) => {
    return question.question.toLowerCase() === "outcome";
  });

  if (!!outcome) {
    const filterOutcome = _.filter(
      arr,
      (item: any) => item.question.toLowerCase() !== "outcome"
    );

    return [...filterOutcome, outcome];
  } else {
    return arr;
  }
};
