import { FC, useState } from "react";
import _ from "lodash";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Asterisk } from "../../common/components/Asterisk";
import { upperCaseFirstLetter } from "../../common/miscellaneous/upperCaseFirstLetter";
import { Controller, useFormContext } from "react-hook-form";

type Option = {
  id: string;
  title: string;
};

type QuestionMultiSelectProps = {
  questionId: string;
  questionItem: any;
  // list question from dto
  questions: any[];
  setQuestions: any;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const QuestionMultiSelect: FC<QuestionMultiSelectProps> = ({
  questionId,
  questionItem,
  questions,
  setQuestions,
}) => {
  const { register, control, clearErrors, reset, setValue } = useFormContext();

  const choiceOptions = _.split(questionItem.questionChoices, ";");
  const questionOption = (choiceOptions ?? []).map((item) => {
    return { id: item, title: item };
  });
  const questionAnswer = _.split(questionItem.value, ";");
  const defaultValue = (questionAnswer ?? [])
    .map((item) => {
      return { id: item, title: item };
    })
    .filter((item) => item.title !== "");

  const [values, setValues] = useState(defaultValue);

  return (
    <FormControl fullWidth sx={{ marginTop: "23px" }}>
      <label className="font-bold">
        {upperCaseFirstLetter(questionItem.question)}
        {questionItem.isRequired ? <Asterisk /> : null}
      </label>

      <Controller
        control={control}
        name={`multi-select-${questionItem.id}`}
        defaultValue={defaultValue}
        rules={{
          required: questionItem.isRequired
            ? "You must answer this question"
            : false,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Autocomplete
            multiple
            id="checkboxes-tags"
            options={questionOption ?? []}
            disableCloseOnSelect
            value={values}
            getOptionLabel={(option: Option) => option.title}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            onChange={(event, data) => {
              setValue(`multi-select-${questionItem.id}`, data);
              setValues(data as any);

              const answerFormat = _.map(data ?? [], "title").join(";");
              const quesWithAnswer = (questions ?? []).map((item) => {
                return item.id === questionItem.id
                  ? { ...item, value: answerFormat }
                  : item;
              });

              setQuestions(quesWithAnswer);
              clearErrors(`multi-select-${questionItem.id}`);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#203c77",
                    },
                  }}
                />
                {option.title}
              </li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      ></Controller>
      <p className="italic">
        {upperCaseFirstLetter(questionItem.questionDescription ?? "")}
      </p>

      <FormHelperText sx={{ color: "red" }}>
        {
          control.getFieldState(`multi-select-${questionItem.id}`).error
            ?.message
        }
      </FormHelperText>
    </FormControl>
  );
};
