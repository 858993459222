import { FC } from "react";
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import { InputLabel, TextField } from "@mui/material";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { SearchButton } from "../../common/components/SearchButton";

type CompanySearchProps = {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues, FieldValues>;
  reset: UseFormReset<FieldValues>;
  setCompanySearch: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const OrganizationSearch: FC<CompanySearchProps> = ({
  register,
  handleSubmit,
  reset,
  setCompanySearch,
  setPage
}) => {

  const handleSubmitSearch = (data: any) => {
    setCompanySearch(data.companyName ?? "" );
    setPage(0);
  };

  const handleReset = () => {
    reset();
    setPage(0);
  };

  return (
    <div className="mt-9 border-2 pt-7 pb-7">
      <form onSubmit={handleSubmit(handleSubmitSearch)}>
        <div className="flex flex-row justify-center items-center">
          {/* <InputLabel sx={{ marginRight: "12px" }}>Organization</InputLabel> */}
          <label className="font-bold mr-3">Organization</label>
          <TextField
            {...register("companyName")}
            defaultValue={""}
            sx={{
              width: "60%",
              "& .MuiInputBase-input": {
                height: "5px",
              },
            }}
          />
        </div>

        <div className="flex flex-center justify-center mt-10 gap-5">
          <ButtonCancelA
            name="Reset"
            onHandleClick={handleReset}
            icon={<ClearIcon />}
          />
          <SearchButton />
        </div>
      </form>
    </div>
  );
};
