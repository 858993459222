import { FC, useEffect, useState } from "react";
import _ from "lodash";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SectionTitle } from "../../common/components/SectionTitle";
import { Asterisk } from "../../common/components/Asterisk";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { TaskTemplateForm } from "./TaskTemplateForm";
import {
  TaskDetailType,
  TaskTemplateWithIdType,
} from "../types/TaskTemplateType";
import { useCreateTemplate } from "../hooks/useCreateTemplate";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { CopyFromExistsTemplate } from "./CopyFromExistsTemplate";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const formControlStyle = {
  marginTop: "20px",
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

export type Option = {
  id: string;
  title: string;
};

export const CreateNewTemplate: FC = () => {
  const methods = useForm({});

  const [tasksTemplate, setTasksTemplate] = useState<TaskTemplateWithIdType[]>([
    {
      id: uuidv4(),
      question: "Outcome",
      questionTypeId: 2,
      questionDescription: "",
      questionChoices:
        "suspend;suspend / interruption;completed;completed / no action taken;delete;external delay;external delay - nigo;internal delay;review only - no action taken;other",
      isRequired: true,
    },
  ]);
  const [isShowExistsTemplate, setIsShowExistsTemplate] = useState(false);
  const [taskDetail, setTaskDetail] = useState<TaskDetailType>({
    companyId: "",
    taskName: "",
    jobTitleIds: null as any,
    taskTypeId: null,
    taskTemplateDetailDtos: [],
    jobTitles: [],
    orderNumber: null,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
  } = methods;
  const navigate = useNavigate();

  const {
    mutationCreateTemplate,
    mutationGetJobsByCompany,
    companiesName,
    jobTitle,
    tasksType,
    questionsType,
  } = useCreateTemplate();

  const handleApplyExist = (data: TaskDetailType) => {
    setTasksTemplate(data.taskTemplateDetailDtos as any);
  };

  const submitCreateTemplate = (data: any) => {
    const taskTemplateDetail = (tasksTemplate ?? []).map((item) => {
      return _.omit(item, "id");
    });

    const payload = {
      companyId: data.organization,
      taskName: data.taskName,
      orderNumber: parseInt(data.orderNumber),
      jobTitles: taskDetail.jobTitles,
      JobTitleIds: _.map(taskDetail.jobTitles, "id"),
      taskTypeId: data.taskTypeId,
      taskTemplateDetailDtos: taskTemplateDetail,
    };

    mutationCreateTemplate.mutate(payload);
  };

  const handleCreateNewQuestion = () => {
    setTasksTemplate([
      ...tasksTemplate,
      {
        id: uuidv4(),
        question: "",
        questionTypeId: 1,
        questionDescription: "",
        questionChoices: "",
        isRequired: false,
      },
    ]);
  };

  const handleCancel = () => {
    navigate("/tasks-list-management");
  };

  useEffect(() => {
    // Set value for company id because receive from copy existing.
    setValue("organization", taskDetail.companyId);
    setValue("taskName", taskDetail.taskName);
    setValue("taskTypeId", taskDetail.taskTypeId);

    // Get list job title after select company id from copy template exists.
    if (!!taskDetail.companyId) {
      mutationGetJobsByCompany.mutate(taskDetail.companyId);
    }
  }, [taskDetail]);

  return (
    <div className="mt-9">
      <SectionTitle name="Create New Template" />

      <Box sx={{ minWidth: 120, marginTop: "60px" }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitCreateTemplate)}>
            <span className="text-sub-title">Task Summary Information</span>
            <div className={"flex flex-col"}>
              <div className="mr-10 ml-10">
                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">
                    Organization <Asterisk />
                  </label>
                  <Controller
                    control={control}
                    name="organization"
                    rules={{ required: "Organization is required" }}
                    defaultValue={taskDetail.companyId ?? ""}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        id="organization"
                        defaultValue={taskDetail.companyId ?? value}
                        value={taskDetail.companyId ?? value ?? ""}
                        onChange={(e) => {
                          // set new value taskDetail for companyId
                          setTaskDetail({
                            ...taskDetail,
                            companyId: e.target.value,
                          } as any);

                          setValue("organization", e.target.value);
                          clearErrors("organization");
                          mutationGetJobsByCompany.mutate(e.target.value);
                        }}
                        style={{ height: "38px" }}
                      >
                        {(companiesName ?? []).map((item: any) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("organization").error?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">
                    Task Name <Asterisk />
                  </label>
                  <TextField
                    {...register("taskName", {
                      required: "Task Name is required",
                    })}
                    value={taskDetail.taskName ?? ""}
                    onChange={(event) => {
                      event.stopPropagation();

                      setTaskDetail({
                        ...taskDetail,
                        taskName: event.target.value,
                      });
                      setValue("taskName", event.target.value);
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "5px",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("taskName").error?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">Job Title</label>
                  <Controller
                    control={control}
                    name="jobTitle"
                    defaultValue={taskDetail.jobTitles ?? []}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        multiple
                        id="checkboxes-tags"
                        options={jobTitle ?? []}
                        disableCloseOnSelect
                        value={taskDetail.jobTitles ?? []}
                        getOptionLabel={(option: Option) => option.title}
                        isOptionEqualToValue={(option, value) =>
                          value === undefined || option.id === value.id
                        }
                        onChange={(event, data) => {
                          setTaskDetail({
                            ...taskDetail,
                            jobTitles: data,
                          } as any);

                          setValue("jobTitle", data);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">
                    Task Type <Asterisk />
                  </label>
                  <Controller
                    control={control}
                    name="taskTypeId"
                    rules={{ required: "Task Type is required" }}
                    defaultValue={taskDetail.taskTypeId ?? ""}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        id="taskTypeId"
                        defaultValue={taskDetail.taskTypeId ?? value ?? ""}
                        value={taskDetail.taskTypeId ?? value ?? ""}
                        onChange={(e) => {
                          // set new value taskDetail for companyId
                          setTaskDetail({
                            ...taskDetail,
                            taskTypeId: e.target.value,
                          } as any);

                          setValue("taskTypeId", e.target.value);
                          clearErrors("taskTypeId");
                        }}
                        style={{ height: "38px" }}
                      >
                        {(tasksType ?? []).map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("taskTypeId").error?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={formControlStyle}>
                  <label className="font-bold">Task Order</label>
                  <TextField
                    type="number"
                    {...register("orderNumber", {
                      valueAsNumber: true,
                      validate: (value) => {
                        if (!Number.isInteger(value)) {
                          return "Task Order value must be an integer number";
                        }
                        if (value < 0 || value > 1000) {
                          return "Task Order value is between 1 and 1000";
                        }

                        return true;
                      },
                    })}
                    value={taskDetail.orderNumber ?? ""}
                    onChange={(event) => {
                      event.stopPropagation();

                      setTaskDetail({
                        ...taskDetail,
                        orderNumber: event.target.value,
                      });
                      setValue("orderNumber", event.target.value);
                      clearErrors("orderNumber");
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "5px",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("orderNumber").error?.message}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className="flex flex-row justify-between items-center mt-6 mb-6">
              <span className="text-sub-title">Task Template</span>
              <div>
                <Button
                  onClick={handleCreateNewQuestion}
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 600,
                    color: "#203C77",
                  }}
                >
                  <AddIcon /> Add New Question
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsShowExistsTemplate(true);
                  }}
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 600,
                    color: "#203C77",
                  }}
                >
                  <ContentCopyIcon /> Copy from existing template
                </Button>
              </div>
            </div>

            <div className="mt-4 mr-10 ml-10">
              {_.map(tasksTemplate, (item: any, index) => {
                return (
                  <TaskTemplateForm
                    key={item.id}
                    index={item.id}
                    value={item}
                    setListTaskTemplate={setTasksTemplate}
                    listTaskTemplate={tasksTemplate}
                    questionsType={questionsType}
                  />
                );
              })}
            </div>

            <FormControl fullWidth sx={btnGroupStyle}>
              <ButtonCancelA
                name="Cancel"
                onHandleClick={handleCancel}
                icon={<CancelIcon sx={{ marginRight: "4px" }} />}
              />
              <ButtonSubmitA
                name="Save"
                icon={<SaveIcon sx={{ marginRight: "4px" }} />}
              />
            </FormControl>
          </form>
        </FormProvider>
      </Box>

      {isShowExistsTemplate ? (
        <CopyFromExistsTemplate
          isShowBox={isShowExistsTemplate}
          setIsShowBox={setIsShowExistsTemplate}
          companiesName={companiesName}
          onHandleApplyCopy={handleApplyExist}
        />
      ) : null}
    </div>
  );
};
