import axios from "axios";

export const fetchUpdateJobTitle = async (data: any) => {
  const body = {
    companyId: data.companyId,
    title: data.title,
    isActive: data.isActive,
  };

  return await axios.post(`/api/company/job/${data.id}`, body);
};
