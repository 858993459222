import { FC } from "react";
import { Button } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

type UserEditProps = {
  id: string;
  onHandleEdit: (event: any) => void;
};

const editBtnStyle = {
  backgroundColor: "#fff",
  minWidth: "15px",

  ":hover": {
    backgroundColor: "#fff",
  },
};

const editIconStyle = {
  color: "#000000",
  fontSize: "20px",
};

export const EditButton: FC<UserEditProps> = ({ id, onHandleEdit }) => {
  return (
    <Button sx={editBtnStyle} onClick={onHandleEdit}>
      <ModeEditIcon sx={editIconStyle}></ModeEditIcon>
    </Button>
  );
};
