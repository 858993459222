import { useState } from "react";
import { useMutation } from "react-query";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { fetchGetTaskDetail } from "../miscellaneous/fetchGetTaskDetail";
import { fetchGetJobsByCompanyId } from "../../jobs-management/miscellaneous/fetchGetJobsByCompanyId";
import { TaskTemplateWithIdType } from "../types/TaskTemplateType";
import { fetchUpdateTaskTemplate } from "../miscellaneous/fetchUpdateTaskTemplate";
import { useNavigate } from "react-router-dom";

export const useUpdateTemplate = () => {
  const [taskDetail, setTaskDetail] = useState<any>(null);
  const [jobTitle, setJobTitle] = useState([]);
  const [jobSelected, setJobSelected] = useState([]);

  const [tasksTemplate, setTasksTemplate] = useState<TaskTemplateWithIdType[]>(
    []
  );

  const navigate = useNavigate();

  const mutationGetDetail = useMutation(fetchGetTaskDetail, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setTaskDetail(res.data);

        const templateDetailWithIds = (
          res.data.taskTemplateDetailDtos ?? []
        ).map((item: TaskTemplateWithIdType) => {
            return {...item, id: uuidv4()}
        });

        setTasksTemplate(templateDetailWithIds);
        setJobSelected(res.data.jobTitleIds);

        mutationGetJobsByCompany.mutate(res.data.companyId);
      }
    },
    onError: () => {},
  });

  const mutationGetJobsByCompany = useMutation(fetchGetJobsByCompanyId, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const jobs = _.map(res.data.data ?? [], (item) => {
          return { id: item.id, title: item.title };
        });

        setJobTitle(jobs as any);
      }
    },
    onError: () => {},
  });

  const mutationUpdateTaskTemplate = useMutation(fetchUpdateTaskTemplate, {
    onSuccess: (res) => {
      if (res.status === 200) {
        // navigate("/tasks-list-management");
        navigate(-1);
      }
    },
    onError: () => {}
  });

  return {
    taskDetail,
    setTaskDetail,
    jobTitle,
    setJobTitle,
    mutationGetDetail,
    mutationUpdateTaskTemplate,
    mutationGetJobsByCompany,
    tasksTemplate,
    setTasksTemplate,
  };
};
