import { FC } from "react";
import Button from "@mui/material/Button";

type PropsButtonSubmitA = {
  name: string;
  icon?: any;
};

const ButtonAStyle = {
  backgroundColor: "#203C77",
  color: "#fff",
  borderRadius: "50px",
  minWidth: "100px",
  textTransform: "capitalize",

  "&:hover": {
    backgroundColor: "#203C77",
  },
};

export const ButtonSubmitA: FC<PropsButtonSubmitA> = ({ name, icon }) => {
  return (
    <Button sx={ButtonAStyle} type="submit">
      {icon}{name}
    </Button>
  );
};
