import { FC, useCallback } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { TextInput } from "../../common/components/TextInput";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { SearchButton } from "../../common/components/SearchButton";

const labelFormControlStyle = {
  fontWeight: "700",
  fontSize: "14px",
  marginRight: "12px",
};

type UserSearchProps = {
  control: Control<FieldValues, any, FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues, FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setUserSearch: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const UserManagementSearch: FC<UserSearchProps> = ({
  control,
  register,
  handleSubmit,
  getValues,
  setValue,
  reset,
  setUserSearch,
  setPage
}) => {
  const onSearchSubmit = useCallback((data: any) => {
    const companyName = getValues("companyName");
    const fullName = getValues("fullName");
    const role = getValues("role");

    setUserSearch({ companyName, fullName, role });
    setPage(0);
  }, []);

  return (
    <div className="mt-9 border-2 pt-7 pb-7">
      <form onSubmit={handleSubmit(onSearchSubmit)}>
        <div className="flex flex-row justify-around">
          <TextInput
            label="Organization"
            value=""
            register={{ ...register("companyName") }}
            labelStyle={labelFormControlStyle}
          />
          <TextInput
            label="User Name"
            value=""
            register={{ ...register("fullName") }}
            labelStyle={labelFormControlStyle}
          />

          <div>
            <label className="font-bold mr-3">Role</label>
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="role"
                  value={value ?? ""}
                  onChange={(e) => {
                    setValue("role", e.target.value);
                  }}
                  style={{ height: "38px", width: "200px" }}
                >
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                  <MenuItem value={"Super Admin"}>Super Admin</MenuItem>
                  <MenuItem value={"User"}>User</MenuItem>
                </Select>
              )}
            ></Controller>
          </div>
        </div>

        <div className="flex flex-center justify-center mt-10 gap-5">
          <ButtonCancelA
            name="Reset"
            onHandleClick={() => {
              reset();
              setPage(0);
            }}
            icon={<ClearIcon />}
          />
          <SearchButton />
        </div>
      </form>
    </div>
  );
};
