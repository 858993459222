import { ServerErrorCommon } from "../../common/components/ServerErrorCommon";
import { Footer } from "../../footer/components/Footer";
import { Header } from "../../header/components/Header";
import { AppRoutes } from "./AppRoutes";
import { Container } from "./Container";

function App() {
  return (
    <>
      <Header />
      <Container>
        <AppRoutes />
        <ServerErrorCommon />
      </Container>
      <Footer />
    </>
  );
}

export default App;
