import { FC, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { upperCaseFirstLetter } from "../../common/miscellaneous/upperCaseFirstLetter";
import { Controller, useFormContext } from "react-hook-form";
import { Asterisk } from "../../common/components/Asterisk";

type QuestionRadioProps = {
  questionId: string;
  questionItem: any;
  // list question from dto
  questions: any[];
  setQuestions: any;
};

export const QuestionRadioButton: FC<QuestionRadioProps> = ({
  questionId,
  questionItem,
  questions,
  setQuestions,
}) => {
  const [values, setValues] = useState(questionItem.value ?? "");
  const { register, control, clearErrors, reset, setValue, getValues } =
    useFormContext();

  const choiceOptions = _.split(questionItem.questionChoices, ";").filter(
    (item) => {
      return item !== "";
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(event.target.value);

    setValue(`radio-button-${questionItem.id}`, event.target.value);

    const questionWithAnswer = (questions ?? []).map((ques) => {
      return ques.id === questionItem.id
        ? { ...ques, value: event.target.value }
        : ques;
    });

    setQuestions(questionWithAnswer);
    clearErrors(`radio-button-${questionItem.id}`);
  };

  return (
    <div key={questionId}>
      <FormControl key={questionId} sx={{ marginTop: "23px" }}>
        <label className="font-bold">
          {upperCaseFirstLetter(questionItem.question)}
          {questionItem.isRequired ? <Asterisk /> : null}
        </label>
        <Controller
          control={control}
          name={`radio-button-${questionItem.id}`}
          defaultValue={values}
          rules={{
            required: questionItem.isRequired
              ? "You must answer this question"
              : false,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={choiceOptions[0] ?? ""}
              name="radio-buttons-group"
              onChange={handleChange}
              value={values}
            >
              {(choiceOptions ?? []).map((item) => {
                return (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={
                      <Radio
                        sx={{
                          "&, &.Mui-checked": {
                            color: "#203c77",
                          },
                        }}
                      />
                    }
                    label={item}
                  />
                );
              })}
            </RadioGroup>
          )}
        ></Controller>

        <p className="italic">
          {upperCaseFirstLetter(questionItem.questionDescription ?? "")}
        </p>

        <FormHelperText sx={{ color: "red" }}>
          {
            control.getFieldState(`radio-button-${questionItem.id}`).error
              ?.message
          }
        </FormHelperText>
      </FormControl>
    </div>
  );
};
