import Box from "@mui/material/Box";
import { FC } from "react";
import { ButtonA } from "../../common/components/ButtonA";
import { userManager } from "../../getOidc";
import {
  cookieInfo,
  deleteCookie,
} from "../../common/miscellaneous/handleCookies";

const cancelBtnStyle = {
  backgroundColor: "#fff",
  color: "#172952",
  width: "100px",
  fontWeight: 600,
  border: "1.5px solid",
  borderRadius: "50px",
  textTransform: "capitalize"
};

export function parseCookie(cookieString: string) {
  return cookieString === ""
    ? {}
    : cookieString
        .split(";")
        .map((subString) => subString.split("="))
        .reduce((accumulator: any, [key, value]: any) => {
          const checkKey = decodeURIComponent(key.trim());

          accumulator[checkKey] = decodeURIComponent(value.trim());

          return accumulator;
        }, {});
}

type signOutProps = {
  onHandleClose: () => void
};

export const SignOut: FC<signOutProps> = ({ onHandleClose }) => {
  const handleSignOut = () => {
    const loginCookie = cookieInfo("loginToken");

    if (!!loginCookie) {
      userManager
        .createSignoutRequest({
          id_token_hint: loginCookie && loginCookie.id_token,
        })
        .then((req) => {
          deleteCookie("loginToken");
          (window as any).location = req.url;
        });
    }
  };

  return (
    <Box component="section" sx={{ p: 2 }}>
      <p className="text-center">Are you sure you want to log out?</p>
      <div className="flex flex-row mt-5 justify-center gap-3">
        <ButtonA
          name="Cancel"
          customStyle={cancelBtnStyle}
          onHandleClick={onHandleClose}
        />
        <ButtonA name="Logout" onHandleClick={handleSignOut} />
      </div>
    </Box>
  );
};
