import axios from "axios";

export const fetchQueryCompanies = async (data: any) => {
  const path = "/api/company";
  const query = `?companyName=${data.companyName ?? ""}`;
  const paging = `&pageSize=${data.pageSize}&pageNumber=${data.pageNumber}`;
  const url = `${path}${query}${paging}`;

  return await axios.get(url);
};
