import { FC, useEffect, useState } from "react";
import _ from "lodash";
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "react-query";
import { MuiChipsInput } from "mui-chips-input";
import { fetchGetAllCompanies } from "../../organization-management/miscellaneous/fetchGetAllCompanies";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { SearchButton } from "../../common/components/SearchButton";
import { useLocation } from "react-router-dom";

type SearchTasksProps = {
  control: Control<FieldValues, any, FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues, FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setTasksSearch: any;
  setSearchParams: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const SearchTasks: FC<SearchTasksProps> = ({
  control,
  register,
  handleSubmit,
  getValues,
  setValue,
  reset,
  setTasksSearch,
  setSearchParams,
  setPage,
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [companiesName, setCompaniesName] = useState([]);
  const [chips, setChips] = useState([]);

  // Cache state of search on URL in component.
  const searchUrlValues = {
    companyId: query.get("companyId") ?? "",
    jobTitles: query.getAll("jobTitles") ?? [],
  };
  const [searchUrl, setSearchUrl] = useState(searchUrlValues);

  const mutationGetCompanies = useMutation(fetchGetAllCompanies, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyName = _.map(res.data.data, (item) => {
          return { name: item.companyName, value: item.id };
        });
        setCompaniesName(companyName as any);
      }
    },
    onError: () => {},
  });

  const handleChange = (newChips: any) => {
    setChips(newChips);
    setSearchUrl({ ...searchUrl, jobTitles: newChips });

    // Update url after change search on UI.
    setSearchParams({ ...searchUrl, jobTitles: newChips });
  };

  const handleReset = () => {
    reset();
    setPage(0);
    setChips([]);

    setSearchUrl({ companyId: "" as string, jobTitles: [] });
    setTasksSearch({ companyId: "" as string, jobTitles: null });
    setSearchParams({});
  };

  const onSearchSubmit = (data: any) => {
    setPage(0);
    const payload = {
      companyId: query.get("companyId") ?? data.organization ?? "",
      jobTitles:
        query.getAll("jobTitles").length > 0
          ? query.getAll("jobTitles")
          : chips,
    };

    if (!!data.organization || chips.length > 0) {
      setSearchParams(payload);
    }

    setTasksSearch(payload);
  };

  useEffect(() => {
    mutationGetCompanies.mutate();
  }, []);

  return (
    <div className="mt-9 border-2 pt-7 pb-7">
      <form onSubmit={handleSubmit(onSearchSubmit)}>
        <div className="flex flex-row justify-center items-center gap-8">
          <div className="flex flex-row justify-center items-center">
            <label className="font-bold mr-3">Organization</label>
            <Controller
              control={control}
              name="organization"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="organization"
                  value={searchUrl.companyId ?? value}
                  onChange={(e) => {
                    setValue("organization", e.target.value);
                    setSearchUrl({ ...searchUrl, companyId: e.target.value });

                    // Update url after change search on UI.
                    setSearchParams({
                      ...searchUrl,
                      companyId: e.target.value,
                    });
                  }}
                  style={{ height: "55px", width: "220px" }}
                >
                  {(companiesName ?? []).map((item: any) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            ></Controller>
          </div>

          <div className="flex flex-row justify-center items-center">
            <label className="font-bold mr-3">Job Title</label>
            <MuiChipsInput
              value={searchUrl.jobTitles ?? chips}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex flex-center justify-center mt-10 gap-5">
          <ButtonCancelA
            name="Reset"
            onHandleClick={handleReset}
            icon={<ClearIcon />}
          />
          <SearchButton />
        </div>
      </form>
    </div>
  );
};
