import { FC } from "react";
import _ from "lodash";
import { useMutation } from "react-query";
import { DeleteButton } from "../../common/components/DeleteButton";
import { fetchDeleteCompany } from "../miscellaneous/fetchDeleteCompany";
import { useSetCompaniesState } from "../hooks/useSetCompaniesState";
import { useCompaniesValue } from "../hooks/useCompaniesValue";

type DeleteCompanyProps = {
  id: string;
  info: string;
};

export const DeleteCompany: FC<DeleteCompanyProps> = ({ id, info }) => {
  const setCompanies = useSetCompaniesState();
  const companies = useCompaniesValue();

  const mutationDeleteCompany = useMutation(fetchDeleteCompany, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyFilter = _.filter(companies, (item) => {
          return item.id !== id;
        });
        setCompanies(companyFilter);
      }
    },
    onError: () => {},
  });

  const handleDelete = (event: any) => {
    event.stopPropagation();
    mutationDeleteCompany.mutate(id);
  };

  return (
    <DeleteButton
      id={id}
      title={"Delete Company"}
      info={info}
      onHandleDelete={handleDelete}
    />
  );
};
