import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import _ from "lodash";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Asterisk } from "../../common/components/Asterisk";
import { SectionTitle } from "../../common/components/SectionTitle";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { fetchUpdateUser } from "../miscellaneous/fetchUpdateUser";
import { fetchGetUserDetail } from "../miscellaneous/fetchGetUserDetail";
import { fetchGetAllCompanies } from "../../organization-management/miscellaneous/fetchGetAllCompanies";
import { fetchGetJobsByCompanyId } from "../../jobs-management/miscellaneous/fetchGetJobsByCompanyId";
import { AxiosError } from "axios";

const formControlStyle = {
  marginTop: "20px",
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

export const UpdateUserDetail: FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [user, setUser] = useState<any>(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const mutationGetUser = useMutation(fetchGetUserDetail, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setUser(res.data);
        mutationGetJobsByCompany.mutate(res.data.companyId);
      }
    },
    onError: () => {},
  });

  const mutationUpdateUser = useMutation(fetchUpdateUser, {
    onSuccess: (response) => {
      if (response && response.status === 200) {
        navigate("/user-management");
      }
    },
    onError: (error: AxiosError) => {
      if (
        error.response &&
        error.response.status === 400 &&
        (error.response.data as string).includes("already taken")
      ) {
        reset(
          {},
          {
            keepValues: true,
          }
        );

        setError("email", {
          type: "manual",
          message: "The e-mail address is already in use",
        });
      }
    },
  });

  const [companiesName, setCompaniesName] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);

  const mutationGetCompanies = useMutation(fetchGetAllCompanies, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyName = _.map(res.data.data, (item) => {
          return { name: item.companyName, value: item.id };
        });
        setCompaniesName(companyName as any);
      }
    },
    onError: () => {},
  });

  const mutationGetJobsByCompany = useMutation(fetchGetJobsByCompanyId, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const jobs = _.map(res.data.data ?? [], (item) => {
          return { id: item.id, name: item.title };
        });

        setJobTitle(jobs as any);
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    mutationGetUser.mutate(userId as string);
    mutationGetCompanies.mutate();
  }, []);

  const onSubmit = (data: any) => {
    const companyName = _.find(companiesName ?? [], (item: any) => {
      return item.value === data.organization;
    });

    const userData = {
      id: userId,
      companyName: companyName.name,
      fullName: data.fullName,
      email: data.email,
      role: data.role,
      isActive: getValues("isActive") === "true" ? true : false,
      jobTitleId: data.jobTitle,
    };

    mutationUpdateUser.mutate(userData);

    if (mutationUpdateUser.isSuccess) {
      navigate("/user-management");
    }
  };

  const handleCancel = () => {
    navigate("/user-management");
  };

  return !!user ? (
    <div className="mt-9">
      <SectionTitle name="Update User Information" />
      <Box sx={{ minWidth: 120, marginTop: "60px" }}>
        <form className="pr-24 pl-24 pb-24" onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Organization <Asterisk />
            </label>
            <Controller
              control={control}
              name="organization"
              defaultValue={user.companyId}
              rules={{ required: "Organization is required" }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="organization"
                  value={value}
                  onChange={(e) => {
                    setValue("organization", e.target.value);
                    mutationGetJobsByCompany.mutate(e.target.value);
                  }}
                  style={{ height: "38px" }}
                >
                  {(companiesName ?? []).map((item: any) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("organization").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              User Email <Asterisk />
            </label>
            <TextField
              {...register("email", {
                required: "User Email is required",
                pattern:
                  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              })}
              defaultValue={user.email}
              sx={{
                "& .MuiInputBase-input": {
                  height: "5px",
                },
              }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("email").error?.message}
              {errors.email &&
                !control.getFieldState("email").error?.message && (
                  <span>Please enter valid email address</span>
                )}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Full Name <Asterisk />
            </label>
            <TextField
              {...register("fullName", { required: "Full Name is required" })}
              defaultValue={user.fullName}
              sx={{
                "& .MuiInputBase-input": {
                  height: "5px",
                },
              }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("fullName").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Role <Asterisk />
            </label>
            <Controller
              control={control}
              name="role"
              rules={{ required: "Role is required" }}
              defaultValue={user.role}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="user-role"
                  value={value ?? ""}
                  onChange={(e) => {
                    setValue("role", e.target.value);
                    clearErrors("role");
                  }}
                  style={{ height: "38px" }}
                >
                  <MenuItem value={"Super Admin"}>Super Admin</MenuItem>
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                  <MenuItem value={"User"}>User</MenuItem>
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("role").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Job Title <Asterisk />
            </label>
            <Controller
              control={control}
              name="jobTitle"
              defaultValue={user.jobTitleId}
              rules={{ required: "Job Title is required" }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="user-job-title"
                  value={value ?? ""}
                  onChange={(e) => {
                    setValue("jobTitle", e.target.value);
                    clearErrors("jobTitle");
                  }}
                  style={{ height: "38px" }}
                >
                  {(jobTitle ?? []).map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("jobTitle").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">Status</label>
            <Controller
              control={control}
              name="isActive"
              defaultValue={user.isActive ? "true" : "false"}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="user-status"
                  value={value ?? "true"}
                  onChange={(e) => {
                    setValue("isActive", e.target.value);
                  }}
                  style={{ height: "38px" }}
                >
                  <MenuItem value={"true"}>Active</MenuItem>
                  <MenuItem value={"false"}>Inactive</MenuItem>
                </Select>
              )}
            ></Controller>
          </FormControl>

          <FormControl fullWidth sx={btnGroupStyle}>
            <ButtonCancelA name="Cancel" onHandleClick={handleCancel} />
            <ButtonSubmitA name="Save" />
          </FormControl>
        </form>
      </Box>
    </div>
  ) : null;
};
