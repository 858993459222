import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FC, ReactNode } from "react";
import ModalClose from "@mui/joy/ModalClose";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const typographyStyle = {
  fontSize: "25px",
  fontWeight: "600",
  color: "#203C77",
};

type PropModalA = {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  onCloseModal: any;
};

export const ModalA: FC<PropModalA> = ({
  title,
  isOpen,
  children,
  onCloseModal,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiBox-root": {
          border: "none",
          borderRadius: "10px",
        },
      }}
    >
      <Box sx={style}>
        <ModalClose onClick={onCloseModal} />
        <Typography
          id="modal-modal-title"
          className="text-center"
          variant="h6"
          component="h2"
          sx={typographyStyle}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </Modal>
  );
};
