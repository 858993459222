import { FC } from "react";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { UserDetailActions } from "./UserDetailActions";
import { useUsersValueDate } from "../hooks/useUsersValueData";
import { TableFooterCommon } from "../../common/components/TableFooterCommon";

const tableCellHeadStyle = {
  fontWeight: 600,
};

type UsersTableProps = {
  isLoading: boolean;
  page: number;
  rowsPerPage: number;
  emptyRows: number;
  totalRecords: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const UserManagementTable: FC<UsersTableProps> = ({
  isLoading,
  page,
  rowsPerPage,
  emptyRows,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const users = useUsersValueDate();

  return users.length > 0 ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead sx={{ backgroundColor: "#F5F4F4"}}>
          <TableRow>
            <TableCell sx={{ maxWidth: "80px", fontWeight: 600 }}>
              Organization
            </TableCell>
            <TableCell sx={tableCellHeadStyle}>User Name</TableCell>
            <TableCell sx={tableCellHeadStyle}>Email</TableCell>
            <TableCell sx={tableCellHeadStyle}>Role</TableCell>
            <TableCell sx={tableCellHeadStyle}>Status</TableCell>
            <TableCell sx={tableCellHeadStyle} align="center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TableRow style={{ height: 300 }}>
              <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                <CircularProgress sx={{ color: "#203c77" }} />
              </TableCell>
            </TableRow>
          ) : (
            users.map((row: any) => (
              <TableRow key={row.id}>
                <TableCell width={"160"} scope="row">
                  {row.companyName}
                </TableCell>
                <TableCell width={"160"}>{row.fullName}</TableCell>
                <TableCell width={"160"}>{row.email}</TableCell>
                <TableCell width={"50"}>{row.role}</TableCell>
                <TableCell width={"50"}>
                  {row.isActive ? "Active" : "Inactive"}
                </TableCell>
                <TableCell width={"160"} align="center">
                  <UserDetailActions userId={row.id} username={row.fullName} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>

        <TableFooterCommon
          data={users}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          onHandleChangePage={handleChangePage}
          onHandleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Table>
    </TableContainer>
  ) : null;
};
