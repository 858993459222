import { OidcClient, UserManager } from "oidc-client";

const oidcConfig = {
    authority: process.env.REACT_APP_AUTH_API_URL ?? "",
    client_id: process.env.REACT_APP_CLIENT_ID ?? "interactive",
    redirect_uri: process.env.REACT_APP_REDIRECT_URL ?? "",
    response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE ?? "",
    scope: process.env.REACT_APP_AUTH_SCOPE ?? "",
    client_secret: process.env.REACT_APP_CLIENT_SECRET_ID ?? ""
};

export const userManager = new OidcClient(oidcConfig);
