import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { fetchGetAllCompanies } from "../../organization-management/miscellaneous/fetchGetAllCompanies";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { SectionTitle } from "../../common/components/SectionTitle";
import { Asterisk } from "../../common/components/Asterisk";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { fetchGetJobDetail } from "../miscellaneous/fetchGetJobDetail";
import { fetchUpdateJobTitle } from "../miscellaneous/fetchUpdateJobTitle";

const formControlStyle = {
  marginTop: "20px",
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

export const UpdateJobTitle: FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const { jobId } = useParams();

  const [companiesName, setCompaniesName] = useState([]);
  const [jobDetail, setJobDetail] = useState<any>(null);

  const mutationGetCompanies = useMutation(fetchGetAllCompanies, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyName = _.map(res.data.data, (item) => {
          return { name: item.companyName, value: item.id };
        });
        setCompaniesName(companyName as any);
      }
    },
    onError: () => {},
  });

  const mutationGetJobDetail = useMutation(fetchGetJobDetail, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setJobDetail(res.data);
      }
    },
    onError: () => {},
  });

  const mutationUpdateJob = useMutation(fetchUpdateJobTitle, {
    onSuccess: (res) => {
      if (res.status === 200) {
        window.location.href = "/company-management?tab=job";
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    mutationGetCompanies.mutate();
    mutationGetJobDetail.mutate(jobId as string);
  }, []);

  const onSubmit = (data: any) => {
    const payload = {
      id: jobId,
      companyId: data.organization,
      title: data.title,
      isActive: data.status === "active" ? true : false,
    };

    mutationUpdateJob.mutate(payload);
  };

  const handleBackToCompanies = () => {
    navigate("/company-management");
  };

  return !!jobDetail ? (
    <div className="mt-9">
      <SectionTitle name="Update Job Title" />
      <Box sx={{ minWidth: 120, marginTop: "60px" }}>
        <form className="pr-24 pl-24 pb-24" onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Organization <Asterisk />
            </label>
            <Controller
              control={control}
              name="organization"
              defaultValue={jobDetail.companyId ?? ""}
              rules={{ required: "Organization is required" }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="organization"
                  value={value ?? ""}
                  onChange={(e) => {
                    setValue("organization", e.target.value);
                    clearErrors("organization");
                  }}
                  style={{ height: "38px", width: "100%" }}
                >
                  {companiesName.map((item: any) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("organization").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">
              Job Title <Asterisk />
            </label>
            <TextField
              {...register("title", {
                required: "Job Title is required",
              })}
              defaultValue={jobDetail.title ?? ""}
              sx={{
                "& .MuiInputBase-input": {
                  height: "5px",
                },
              }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("title").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={formControlStyle}>
            <label className="font-bold">Status</label>
            <Controller
              control={control}
              name="status"
              defaultValue={jobDetail.isActive === true ? "active" : "inactive"}
              rules={{ required: "Status is required" }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="status"
                  value={value ?? "active"}
                  onChange={(e) => {
                    setValue("status", e.target.value);
                    clearErrors("status");
                  }}
                  style={{ height: "38px" }}
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                </Select>
              )}
            ></Controller>
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState("status").error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={btnGroupStyle}>
            <ButtonCancelA
              name="Cancel"
              onHandleClick={handleBackToCompanies}
              icon={<CancelIcon sx={{ marginRight: "4px" }} />}
            />
            <ButtonSubmitA
              name="Save"
              icon={<SaveIcon sx={{ marginRight: "4px" }} />}
            />
          </FormControl>
        </form>
      </Box>
    </div>
  ) : null;
};
