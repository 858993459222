import { FC } from "react";
import _ from "lodash";
import { TableCommonProps } from "../../common/types/tableType";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tableCellHeadStyle } from "../../common/styles/tableStyle";
import { TableFooterCommon } from "../../common/components/TableFooterCommon";
import { EditButton } from "../../common/components/EditButton";
import { DeleteTaskTemplate } from "./DeleteTaskTemplate";

export const TasksListTable: FC<TableCommonProps> = ({
  data,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  setData,
  isLoading,
}) => {
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/update-task-template/${id}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead sx={{ backgroundColor: "#F5F4F4" }}>
          <TableRow>
            <TableCell sx={{ maxWidth: "80px", fontWeight: 600 }}>
              Organization
            </TableCell>
            <TableCell sx={tableCellHeadStyle}>Job Title</TableCell>
            <TableCell sx={tableCellHeadStyle}>Task Order</TableCell>
            <TableCell sx={tableCellHeadStyle}>Task Name</TableCell>
            <TableCell sx={tableCellHeadStyle}>Task Type</TableCell>
            <TableCell sx={tableCellHeadStyle} align="center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>

        {isLoading ? (
          <TableBody>
            <TableRow style={{ height: 300 }}>
              <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                <CircularProgress sx={{ color: "#203c77" }} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {(data ?? []).length > 0
              ? data.map((row: any) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell width={"160"} scope="row">
                        {row.companyName}
                      </TableCell>
                      <TableCell width={"160"}>
                        {_.join(row.jobTitles, ", ")}
                      </TableCell>
                      <TableCell width={"100"}>{row.orderNumber}</TableCell>
                      <TableCell width={"160"}>{row.taskName}</TableCell>
                      <TableCell width={"50"}>{row.taskType}</TableCell>
                      <TableCell width={"100"} align="center">
                        <div>
                          <EditButton
                            id={row.id}
                            onHandleEdit={() => {
                              handleEdit(row.id);
                            }}
                          />
                          <DeleteTaskTemplate
                            id={row.id}
                            info={row.taskName}
                            tasks={data}
                            setTasks={setData}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        )}

        <TableFooterCommon
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          onHandleChangePage={handleChangePage}
          onHandleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Table>
    </TableContainer>
  );
};
