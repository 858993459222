import axios from "axios";

export const fetchQueryJobTitle = async (data: any) => {
  const path = "/api/company/job";
  const queryParam = `?companyId=${data.companyId}&title=${data.title}`;
  const paging = `&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
  const url = `${path}${queryParam}${paging}`;

  return await axios.get(url);
};
