import { FC } from "react";
import Button from "@mui/material/Button";

type PropsButtonA = {
  id?: string;
  name: string;
  onHandleClick: () => void;
  customStyle?: any;
  icon?: any;
};

const ButtonAStyle = {
  backgroundColor: "#203C77",
  color: "#fff",
  borderRadius: "50px",
  minWidth: "100px",
  fontWeight: 100,
  "&:hover": {
    backgroundColor: "#203C77",
  },
  textTransform: "capitalize"
};

export const ButtonA: FC<PropsButtonA> = ({
  id,
  name,
  onHandleClick,
  customStyle,
  icon
}) => {
  return (
    <Button
      className={""}
      id={id}
      sx={customStyle ?? ButtonAStyle}
      onClick={onHandleClick}
    >
      {icon}{name}
    </Button>
  );
};
