import { FC } from "react";
import { ModalA } from "../../common/components/ModalA";
import { ButtonA } from "../../common/components/ButtonA";

type NotifyExportModalProps = {
  isShowModal: boolean;
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ButtonOKStyle = {
  backgroundColor: "#203C77",
  color: "#fff",
  borderRadius: "50px",
  minWidth: "100px",
  width: "100%",
  fontWeight: 100,
  "&:hover": {
    backgroundColor: "#203C77",
  },
  textTransform: "capitalize",
};

export const NotifyExportModal: FC<NotifyExportModalProps> = ({
  isShowModal,
  setIsShowModal,
}) => {
  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  return (
    <ModalA
      title="Information Message"
      onCloseModal={handleCloseModal}
      isOpen={isShowModal}
    >
      <p className="text-center mt-3 mb-5">
        We are generating the file as per your request and it might take some
        time. An email notification will be sent to your email once your file is
        ready to be downloaded.
      </p>

      <ButtonA
        name="OK"
        onHandleClick={handleCloseModal}
        customStyle={ButtonOKStyle}
      />
    </ModalA>
  );
};
