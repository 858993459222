import { FC } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { DeleteCompany } from "./DeleteCompany";
import { useSelectTableRow } from "../../common/hooks/useSelectTableRow";
import { TableFooterCommon } from "../../common/components/TableFooterCommon";
import { TableHeadCheckbox } from "../../common/components/TableHeadCheckbox";
import { TableCellCheckbox } from "../../common/components/TableCellCheckbox";
import { ButtonA } from "../../common/components/ButtonA";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { useCompaniesValue } from "../hooks/useCompaniesValue";
import { EditCompanyDetail } from "./EditCompanyDetail";
import { fetchDisableCompanies } from "../miscellaneous/fetchDisableCompanies";

const tableCellHeadStyle = {
  fontWeight: 600,
};

type OrganizationTableProps = {
  page: number;
  rowsPerPage: number;
  totalRecords: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const OrganizationTable: FC<OrganizationTableProps> = ({
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const navigate = useNavigate();
  const companies = useCompaniesValue();
  const { selected, handleSelectAllClick, isSelected, handleToggle } =
    useSelectTableRow(companies);

  const mutateDisableCompanies = useMutation(fetchDisableCompanies, {
    onSuccess: (res) => {},
    onError: () => {},
  });

  const handleNavigateToCreate = () => {
    navigate("/create-company");
  };

  const disableCompanies = () => {
    mutateDisableCompanies.mutate(selected);
    navigate(0);
  };

  return (
    <div className="mt-4">
      <div className="flex flex-row justify-end gap-4 pb-3">
        <ButtonCancelA
          name={"Disable"}
          onHandleClick={disableCompanies}
          icon={<DeleteSweepIcon />}
        />
        <ButtonA
          name={"Create"}
          onHandleClick={handleNavigateToCreate}
          icon={<CreateIcon />}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead sx={{ backgroundColor: "#F5F4F4" }}>
            <TableRow>
              <TableCell sx={{ maxWidth: "10px", fontWeight: 600 }}>
                <TableHeadCheckbox
                  selected={selected}
                  data={companies}
                  onHandleSelectAllClick={handleSelectAllClick}
                />
              </TableCell>
              <TableCell sx={tableCellHeadStyle}>Organization</TableCell>
              <TableCell sx={tableCellHeadStyle}>Status</TableCell>
              <TableCell sx={tableCellHeadStyle} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {companies.map((item: any) => {
              const isItemSelected = isSelected(item.id);
              return (
                <TableRow
                  key={item.id}
                  tabIndex={-1}
                  selected={isItemSelected}
                  onClick={(event) => handleToggle(event, item.id)}
                >
                  <TableCell width={"10"} scope="row">
                    <TableCellCheckbox isItemSelected={isItemSelected} />
                  </TableCell>
                  <TableCell width={"200"}>{item.companyName}</TableCell>
                  <TableCell width={"160"}>
                    {item.isActive ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell width={"160"} align="center">
                    <div>
                      <EditCompanyDetail userId={item.id} />
                      <DeleteCompany id={item.id} info={item.companyName} />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooterCommon
            data={companies}
            rowsPerPage={rowsPerPage}
            page={page}
            totalRecords={totalRecords}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
