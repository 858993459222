import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../app/images/logo.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { UserInfo } from "./UserInfo";
import { Container } from "../../app/components/Container";
import { isExistsLogin } from "../../common/miscellaneous/handleCookies";

const navItemStyle = {
  color: "#000000",
  fontWeight: 600,
};

export const Header: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const open = Boolean(anchorEl);

  const [isShowUserInfo, setIsShowUserInfo] = useState(false);

  const userRole = !!localStorage.getItem("rolesJson")
    ? JSON.parse(localStorage.getItem("rolesJson") as string)
    : [];

  const isRoleAdmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLocaleLowerCase() === "admin";

  const isRoleSupperAdmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLocaleLowerCase() === "super admin";

  useEffect(() => {
    setIsShowUserInfo(isExistsLogin());
  }, [location]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <header className="">
      <nav className="bg-white border-gray-200 py-4 dark:bg-gray-800 border-b-2 max-h-20">
        <Container>
          <div className="flex flex-wrap justify-between">
            <a href="#" className="">
              <img
                src={logo}
                className="mr-3 h-6 sm:h-12"
                alt="Implementation Partner Logo"
              />
            </a>

            {isShowUserInfo ? (
              <div className="flex items-center lg:order-2">{<UserInfo />}</div>
            ) : null}

            {isShowUserInfo ? (
              <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
                <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                    <Link to={"/task-submission"}>
                      <Button id="basic-button" sx={navItemStyle}>
                        Home
                      </Button>
                    </Link>
                  </li>
                  {(isRoleAdmin || isRoleSupperAdmin) ? (
                    <li>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleOpen}
                        sx={navItemStyle}
                      >
                        Administration
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <Link to={"/user-management"}>
                          <MenuItem onClick={handleClose}>
                            User Management
                          </MenuItem>
                        </Link>
                        <Link to={"/tasks-list-management"}>
                          <MenuItem onClick={handleClose}>
                            Task List Management
                          </MenuItem>
                        </Link>
                        <Link to={"/company-management"}>
                          <MenuItem onClick={handleClose}>
                            Company Management
                          </MenuItem>
                        </Link>
                      </Menu>
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : null}
          </div>
        </Container>
      </nav>
    </header>
  );
};
