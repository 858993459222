import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { addNewUser } from "../miscellaneous/addNewUser";
import { Asterisk } from "../../common/components/Asterisk";
import { SectionTitle } from "../../common/components/SectionTitle";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import { fetchGetAllCompanies } from "../../organization-management/miscellaneous/fetchGetAllCompanies";
import { fetchGetJobsByCompanyId } from "../../jobs-management/miscellaneous/fetchGetJobsByCompanyId";
import { ImportUsersModal } from "./ImportUsersModal";
import { AxiosError } from "axios";
import { CapchaTokenModal } from "../../task-submission/components/CapchaTokenModal";

const formControlStyle = {
  marginTop: "20px",
};

const btnGroupStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "20px",
  marginTop: "60px",
};

export const CreateNewUser: FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();

  const [companiesName, setCompaniesName] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);

  const [isShowCapchaModal, setIsShowCapchaModal] = useState(false);
  const [captChaToken, setCaptChaToken] = useState(null);

  const mutationGetCompanies = useMutation(fetchGetAllCompanies, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const companyName = _.map(res.data.data, (item) => {
          return { name: item.companyName, value: item.id };
        });
        setCompaniesName(companyName as any);
      }
    },
    onError: () => { },
  });

  const mutationGetJobsByCompany = useMutation(fetchGetJobsByCompanyId, {
    onSuccess: (res) => {
      if (res.status === 200) {
        const jobs = _.map(res.data.data ?? [], (item) => {
          return { id: item.id, name: item.title };
        });

        setJobTitle(jobs as any);
      }
    },
    onError: () => { },
  });

  const mutationAddUser = useMutation(addNewUser, {
    onSuccess: (response) => {
      if (response && response.status === 200) {
        navigate("/user-management");
      }
    },
    onError: (error: AxiosError) => {
      if (
        error.response &&
        error.response.status === 400
        &&
        (error.response.data as string).includes("already taken")
      ) {
        reset(
          {},
          {
            keepValues: true,
          }
        );

        setError("email", {
          type: "manual",
          message: "The e-mail address is already in use",
        });
      }
    },
  });

  const [isShowImportModal, setIsShowImportModal] = useState(false);

  useEffect(() => {
    mutationGetCompanies.mutate();
  }, []);

  const onSubmit = (data: any) => {

    if (!captChaToken) {
      setIsShowCapchaModal(true);
    } else {
      const isActive = getValues("status") === "active" ? true : false;
      const companyName = _.find(companiesName ?? [], (item: any) => {
        return item.value === data.organization;
      });
  
      const userData = {
        ...data,
        jobTitleId: data.jobTitle,
        companyName: companyName.name,
        isActive: isActive,
      };
  
      mutationAddUser.mutate(userData);
    }
  };

  const handleCancel = () => {
    navigate("/user-management");
  };

  return companiesName.length > 0 ? (
    <>
      <div className="mt-9">
        <div className="flex flex-row items-center justify-between">
          <SectionTitle name="Create New User" />
          <ButtonCancelA
            name={"Import from file"}
            onHandleClick={() => {
              setIsShowImportModal(true);
            }}
          />
        </div>
        <Box sx={{ minWidth: 120, marginTop: "60px" }}>
          <form className="pr-24 pl-24 pb-24" onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={formControlStyle}>
              <label className="font-bold">
                Organization <Asterisk />
              </label>
              <Controller
                control={control}
                name="organization"
                rules={{ required: "Organization is required" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="organization"
                    value={value ?? ""}
                    onChange={(e) => {
                      setValue("organization", e.target.value);
                      clearErrors("organization");
                      mutationGetJobsByCompany.mutate(e.target.value);
                    }}
                    style={{ height: "38px" }}
                  >
                    {(companiesName ?? []).map((item: any) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              ></Controller>
              <FormHelperText sx={{ color: "red" }}>
                {control.getFieldState("organization").error?.message}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth sx={formControlStyle}>
              <label className="font-bold">
                User Email <Asterisk />
              </label>
              <TextField
                {...register("email", {
                  required: "User Email is required",
                  pattern:
                    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                })}
                defaultValue={""}
                onChange={() => {
                  clearErrors("email");
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    height: "5px",
                  },
                }}
              />
              <FormHelperText sx={{ color: "red" }}>
                {control.getFieldState("email").error?.message}
                {errors.email &&
                  !control.getFieldState("email").error?.message && (
                    <span>Please enter valid email address</span>
                  )}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth sx={formControlStyle}>
              <label className="font-bold">
                Full Name <Asterisk />
              </label>
              <TextField
                {...register("fullName", { required: "Full Name is required" })}
                defaultValue={""}
                sx={{
                  "& .MuiInputBase-input": {
                    height: "5px",
                  },
                }}
              />
              <FormHelperText sx={{ color: "red" }}>
                {control.getFieldState("fullName").error?.message}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth sx={formControlStyle}>
              <label className="font-bold">
                Role <Asterisk />
              </label>
              <Controller
                control={control}
                name="role"
                rules={{ required: "Role is required" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="user-role"
                    value={value ?? ""}
                    onChange={(e) => {
                      setValue("role", e.target.value);
                      clearErrors("role");
                    }}
                    style={{ height: "38px" }}
                  >
                    <MenuItem value={"super admin"}>Super Admin</MenuItem>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"user"}>User</MenuItem>
                  </Select>
                )}
              ></Controller>
              <FormHelperText sx={{ color: "red" }}>
                {control.getFieldState("role").error?.message}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth sx={formControlStyle}>
              <label className="font-bold">Job Title</label>
              <Controller
                control={control}
                name="jobTitle"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="user-job-title"
                    value={value ?? ""}
                    onChange={(e) => {
                      setValue("jobTitle", e.target.value);
                      clearErrors("jobTitle");
                    }}
                    style={{ height: "38px" }}
                  >
                    {(jobTitle ?? []).map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              ></Controller>
            </FormControl>

            <FormControl fullWidth sx={formControlStyle}>
              <label className="font-bold">Status</label>
              <Controller
                control={control}
                name="status"
                rules={{ required: "Status is required" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="status"
                    value={value ?? ""}
                    onChange={(e) => {
                      setValue("status", e.target.value);
                      clearErrors("status");
                    }}
                    style={{ height: "38px" }}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
                )}
              ></Controller>
              <FormHelperText sx={{ color: "red" }}>
                {control.getFieldState("status").error?.message}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth sx={btnGroupStyle}>
              <ButtonCancelA
                name="Cancel"
                onHandleClick={handleCancel}
                icon={<CancelIcon sx={{ marginRight: "4px" }} />}
              />
              <ButtonSubmitA
                name="Save"
                icon={<SaveIcon sx={{ marginRight: "4px" }} />}
              />
            </FormControl>
          </form>
        </Box>
      </div>

      <ImportUsersModal
        companiesName={companiesName}
        isShowImportModal={isShowImportModal}
        setIsShowImportModal={setIsShowImportModal}
      />

      <CapchaTokenModal
        isShowModal={isShowCapchaModal}
        setIsShowModal={setIsShowCapchaModal}
        setCaptChaToken={setCaptChaToken as any}
        content={"Please verify you are human before add new user processing"}
      />
    </>
  ) : null;
};
