import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";

export const TableNoContent: FC = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={8}
        sx={{
          height: "300px",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        No Data Available
      </TableCell>
    </TableRow>
  );
};
