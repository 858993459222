import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchTasks } from "./SearchTasks";
import { TasksListTable } from "./TasksListTable";
import { useGetAvailableTask } from "../hooks/useGetAvailableTask";
import { ButtonA } from "../../common/components/ButtonA";
import { SectionTitle } from "../../common/components/SectionTitle";
import { usePaginationTable } from "../../common/hooks/usePaginationTable";

export const TasksListManagement: FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm();

  const [tasksSearch, setTasksSearch] = useState({
    companyId: "",
    jobTitles: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    availableTasks,
    totalRecords,
    setAvailableTask,
    mutationQueryAvailableTask,
  } = useGetAvailableTask();

  const {
    page,
    rowsPerPage,
    emptyRows,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
    setRowsPerPage
  } = usePaginationTable(availableTasks);

  const navigate = useNavigate();

  useEffect(() => {
    setRowsPerPage(-1);
  }, []);

  useEffect(() => {
    const urlQuery = {
      companyId: searchParams.get("companyId") ?? null,
      jobTitles: searchParams.getAll("jobTitles") ?? null,
    };

    const queryParam = {
      pageSize: rowsPerPage,
      pageNum: page + 1,
      companyId: urlQuery.companyId ?? tasksSearch.companyId,
      jobTitles: urlQuery.jobTitles ?? tasksSearch.jobTitles,
    };

    mutationQueryAvailableTask.mutate(queryParam);
  }, [
    page,
    rowsPerPage,
    JSON.stringify(tasksSearch)
  ]);

  return (
    <div className="mt-9">
      <div className="flex justify-between">
        <SectionTitle name="Task List Management" />
        <ButtonA
          name="Create"
          icon={<CreateIcon />}
          onHandleClick={() => {
            navigate("/create-new-template");
          }}
        />
      </div>
      <div>
        <SearchTasks
          control={control}
          register={register}
          handleSubmit={handleSubmit}
          setValue={setValue}
          getValues={getValues}
          setTasksSearch={setTasksSearch}
          setSearchParams={setSearchParams}
          setPage={setPage}
          reset={reset}
        />
        <div className="mt-7">
          <TasksListTable
            data={availableTasks}
            page={page}
            rowsPerPage={rowsPerPage}
            // emptyRows={emptyRows}
            totalRecords={totalRecords}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            setData={setAvailableTask}
            isLoading={mutationQueryAvailableTask.isLoading}
          />
        </div>
      </div>
    </div>
  );
};
