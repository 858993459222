import { FC } from "react";
import { Button, CircularProgress } from "@mui/material";

type ButtonALoadingProp = {
  name: String;
};

const ButtonALoadingStyle = {
  backgroundColor: "#203C77",
  color: "#fff",
  borderRadius: "50px",
  minWidth: "100px",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "#203C77",
  },
  textTransform: "capitalize",
};

export const ButtonALoading: FC<ButtonALoadingProp> = ({ name }) => {
  return (
    <Button disabled variant="contained" sx={ButtonALoadingStyle}>
      <CircularProgress
        sx={{ color: "#203c77", marginRight: "5px" }}
        size={20}
      />
      {name}
    </Button>
  );
};
