import { FC, useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import { SectionTitle } from "../../common/components/SectionTitle";
import { OrganizationManagement } from "../../organization-management/components/OrganizationManagement";
import { JobsManagement } from "../../jobs-management/components/JobsManagement";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const tabStyle = {
  fontWeight: 600,
  color: "#203C77",
  "&.Mui-selected": { color: "#203C77", backgroundColor: "#EBF2FE" },
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CompanyManagement: FC = () => {
  const [value, setValue] = useState(0);
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get("tab");

  useEffect(() => {
    setValue(tabParam === "job" ? 1 : 0);
  }, [tabParam]);

  return (
    <div className="mt-9 mb-14">
      <div className="flex flex-row justify-between">
        <SectionTitle name="Company Management" />
      </div>
      <div className="mt-9">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="user management"
              sx={{ borderBottom: "none" }}
              TabIndicatorProps={{
                sx: { backgroundColor: "#203C77" },
              }}
            >
              <Tab label="Organization" {...a11yProps(0)} sx={tabStyle} />
              <Tab label="Job Title" {...a11yProps(1)} sx={tabStyle} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <OrganizationManagement />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <JobsManagement />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};
