import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../../home/components/Home";
import { TaskSubmission } from "../../task-submission/components/TaskSubmission";
import { CreateNewTask } from "../../task-submission/components/CreateNewTask";
import { UserManagement } from "../../user-management/components/UserManagement";
import { CreateNewUser } from "../../user-management/components/CreateNewUser";
import { UpdateUserDetail } from "../../user-management/components/UpdateUserDetail";
import { CompanyManagement } from "../../company-management/components/CompanyManagement";
import { CreateNewCompany } from "../../organization-management/components/CreateNewCompany";
import { UpdateCompanyInfo } from "../../organization-management/components/UpdateCompanyInfo";
import { CreateNewJobTitle } from "../../jobs-management/components/CreateNewJobTitle";
import { UpdateJobTitle } from "../../jobs-management/components/UpdateJobTitle";
import { TasksListManagement } from "../../tasks-list-management/components/TasksListManagement";
import { CreateNewTemplate } from "../../tasks-list-management/components/CreateNewTemplate";
import { UpdateTemplateDetail } from "../../tasks-list-management/components/UpdateTemplateDetail";
import { UpdateTaskSubmission } from "../../task-submission/components/UpdateTaskSubmission";
import { PageNotFound } from "../../home/components/PageNotFound";
import { isExistsLogin } from "../../common/miscellaneous/handleCookies";
import { PreAccess } from "./PreAccess";

export const AppRoutes: FC = () => {
  const userRole = !!localStorage.getItem("rolesJson")
    ? JSON.parse(localStorage.getItem("rolesJson") as string)
    : [];

  const isRoleAdmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLowerCase() === "admin";

  const isRoleSupperAdmin =
    userRole &&
    userRole.length > 0 &&
    userRole[0]?.Name &&
    userRole[0]?.Name.toLowerCase() === "super admin";

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/task-submission/*" element={<PreAccess />} />

      {isExistsLogin() ? (
        <>
          <Route path="/task-submission" element={<TaskSubmission />} />
          <Route path="/create-task" element={<CreateNewTask />} />
          <Route
            path="/update-task/:taskId"
            element={<UpdateTaskSubmission />}
          />
        </>
      ) : <Route path="*" element={<PageNotFound />} />}

      {isRoleAdmin || isRoleSupperAdmin ? (
        <>
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/create-new-user" element={<CreateNewUser />} />
          <Route path="/update-user/:userId" element={<UpdateUserDetail />} />
          <Route path="/company-management" element={<CompanyManagement />} />
          <Route path="/create-company" element={<CreateNewCompany />} />
          <Route
            path="/update-company/:companyId"
            element={<UpdateCompanyInfo />}
          />
          <Route path="/create-job-title" element={<CreateNewJobTitle />} />
          <Route path="/update-job/:jobId" element={<UpdateJobTitle />} />
          <Route
            path="/tasks-list-management"
            element={<TasksListManagement />}
          />
          <Route path="/create-new-template" element={<CreateNewTemplate />} />
          <Route
            path="/update-task-template/:id"
            element={<UpdateTemplateDetail />}
          />
        </>
      ) : (
        <Route path="*" element={<PageNotFound />} />
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
