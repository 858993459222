import { useState } from "react";
import { useMutation } from "react-query";
import _ from "lodash";
import { fetchGetTaskDetail } from "./../../tasks-list-management/miscellaneous/fetchGetTaskDetail";
import { fetchGetTemplateByCompanyId } from "../../tasks-list-management/miscellaneous/fetchGetTemplateByCompanyId";
import { useSetTaskQuestionsState } from "./useSetTaskQuestionsState";
import { fetchCreateTaskSubmit } from "../miscellaneous/fetchCreateTaskSubmit";
import { useNavigate } from "react-router-dom";

export const useCreateTaskSubmit = (
  setIsShowToastSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  resetForm?: any
) => {
  const [taskTemplate, setTaskTemplate] = useState([]);
  const setQuestions = useSetTaskQuestionsState();
  const setTaskQuestions = useSetTaskQuestionsState();

  const [outcomeQuestion, setOutcomeQuestion] = useState<any>(null);

  const navigate = useNavigate();

  const mutationGetTaskName = useMutation(fetchGetTemplateByCompanyId, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setTaskTemplate(res.data.taskTemplateDtos);
      }
    },
    onError: () => {},
  });

  const mutateGetQuestionsByTask = useMutation(fetchGetTaskDetail, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setTaskQuestions(res.data.taskTemplateDetailDtos ?? []);

        // Find outcome question.
        const outcome = _.find((res.data.taskTemplateDetailDtos ?? []), (item) => {
          return item.question.toLowerCase() === "outcome" && item.questionTypeId === 2
        });
        setOutcomeQuestion(outcome ?? null);
      }
    },
    onError: () => {},
  });

  const mutateCreateNewTaskSubmit = useMutation(fetchCreateTaskSubmit, {
    onSuccess: (res) => {
      if (res.status === 200) {
        setIsShowToastSuccess(true);
        resetForm();
        setQuestions([]);
      }
    },
    onError: () => {},
  });

  return {
    taskTemplate,
    outcomeQuestion,
    setTaskTemplate,
    setTaskQuestions,
    mutationGetTaskName,
    mutateGetQuestionsByTask,
    mutateCreateNewTaskSubmit,
  };
};
