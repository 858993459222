import { FC } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type UserEditProps = {
  userId: string;
};

const editBtnStyle = {
  backgroundColor: "#fff",
  minWidth: "15px",

  ":hover": {
    backgroundColor: "#fff",
  },
};

const editIconStyle = {
  color: "#000000",
  fontSize: "20px",
};

export const UserDetailEdit: FC<UserEditProps> = ({ userId }) => {
  const navigate = useNavigate();

  const handleNavigateEdit = () => {
    navigate(`/update-user/${userId}`);
  };

  return (
    <>
      <Button sx={editBtnStyle} onClick={handleNavigateEdit}>
        <ModeEditIcon sx={editIconStyle}></ModeEditIcon>
      </Button>
    </>
  );
};
