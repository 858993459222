import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { FC, useState } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { Asterisk } from "../../common/components/Asterisk";
import {
  TaskTemplateFormProps,
  TaskTemplateWithIdType,
} from "../types/TaskTemplateType";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { textAreaStyle } from "../styles/TaskTemplateFormStyle";

export const TaskTemplateForm: FC<TaskTemplateFormProps> = React.memo(
  ({ index, value, setListTaskTemplate, listTaskTemplate, questionsType }) => {
    const { register, control, clearErrors, setValue } = useFormContext();

    const [task, setTask] = useState<TaskTemplateWithIdType>(
      _.find(listTaskTemplate ?? [], (object: TaskTemplateWithIdType) => {
        return object.id === index;
      }) as any
    );

    const handleRemoveItem = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.preventDefault();
      event.stopPropagation();
      const filter = _.filter(listTaskTemplate, (item: any) => {
        return item.id !== task.id;
      });

      setListTaskTemplate(filter);
    };

    const handleDuplicateItem = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.preventDefault();
      event.stopPropagation();

      const duplicate = {
        id: uuidv4(),
        question: task.question,
        questionTypeId: task.questionTypeId,
        questionDescription: task.questionDescription,
        questionChoices: task.questionChoices,
        isRequired: task.isRequired,
      };

      setListTaskTemplate([...listTaskTemplate, duplicate]);
    };

    return (
      <div key={index}>
        <div className="flex flex-row justify-between gap-10 mt-3">
          <FormControl sx={{ width: "70%" }}>
            <label className="font-bold">
              Question <Asterisk />
            </label>
            <TextField
              {...register(`question-${task.id}`, {
                required: "Question is required",
              })}
              key={index}
              value={task.question}
              onChange={(event) => {
                setTask({ ...task, question: event.target.value });
                clearErrors(`question-${task.id}`);

                const newTasks = _.map(listTaskTemplate, (object) => {
                  return object.id === task.id
                    ? { ...task, question: event.target.value }
                    : object;
                });

                setListTaskTemplate(newTasks);
              }}
              sx={{
                "& .MuiInputBase-input": {
                  height: "5px",
                },
              }}
            />
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState(`question-${task.id}`).error?.message}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: "30%" }}>
            <label className="font-bold">
              Question Type <Asterisk />
            </label>
            <Select
              id="status"
              defaultValue={task.questionTypeId ?? 1}
              onChange={(event) => {
                setTask({
                  ...task,
                  questionTypeId: parseInt(event.target.value as string),
                });

                const newTasks = _.map(listTaskTemplate, (object) => {
                  return object.id === task.id
                    ? {
                        ...task,
                        questionTypeId: parseInt(event.target.value as string),
                      }
                    : object;
                });

                setListTaskTemplate(newTasks);
              }}
              style={{ height: "38px" }}
            >
              {(questionsType ?? []).map((item: any) => {
                return (
                  <MenuItem key={item.id} value={parseInt(item.id)}>
                    {item.type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <FormControl fullWidth sx={{ marginTop: "20px" }}>
          <label className="font-bold">Description</label>
          <TextareaAutosize
            placeholder=""
            value={task.questionDescription ?? ""}
            minRows={4}
            onChange={(event) => {
              setTask({ ...task, questionDescription: event.target.value });

              const newTasks = _.map(listTaskTemplate, (object) => {
                return object.id === task.id
                  ? { ...task, questionDescription: event.target.value }
                  : object;
              });

              setListTaskTemplate(newTasks);
            }}
            style={textAreaStyle}
          />
        </FormControl>

        {task.questionTypeId !== 1 ? (
          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <label className="font-bold">
              Choice <Asterisk />
            </label>
            <Controller
              control={control}
              name={`choice-${task.id}`}
              rules={{ required: "Choice is required" }}
              defaultValue={task.questionChoices ?? ""}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextareaAutosize
                  placeholder="Press semicolon to separate options"
                  minRows={2}
                  value={task.questionChoices ?? ""}
                  onChange={(event) => {
                    setValue(`choice-${task.id}`, event.target.value);
                    setTask({ ...task, questionChoices: event.target.value });

                    const newTasks = _.map(listTaskTemplate, (object) => {
                      return object.id === task.id
                        ? { ...task, questionChoices: event.target.value }
                        : object;
                    });
                    setListTaskTemplate(newTasks);

                    clearErrors(`choice-${task.id}`);
                  }}
                  style={textAreaStyle}
                />
              )}
            />
            <FormHelperText sx={{ color: "red" }}>
              {control.getFieldState(`choice-${task.id}`).error?.message}
            </FormHelperText>
          </FormControl>
        ) : null}

        <div>
          <div className="mt-5">
            <Checkbox
              checked={task.isRequired}
              onChange={(event) => {
                setTask({ ...task, isRequired: event.target.checked });

                const newTasks = _.map(listTaskTemplate, (object) => {
                  return object.id === task.id
                    ? { ...task, isRequired: event.target.checked }
                    : object;
                });
                setListTaskTemplate(newTasks);
              }}
            />
            <label className="font-bold">Required</label>
          </div>

          <div className="flex flex-row justify-end">
            <Button
              onClick={handleRemoveItem}
              sx={{
                color: "#203C77",
                textTransform: "capitalize",
                fontWeight: 600,
              }}
            >
              <DeleteIcon /> Delete
            </Button>

            <Button
              onClick={handleDuplicateItem}
              sx={{
                color: "#203C77",
                textTransform: "capitalize",
                fontWeight: 600,
              }}
            >
              <ContentCopyIcon />
              Duplicate
            </Button>
          </div>
        </div>

        <hr className="border-dashed border border-gray-400 mt-4" />
      </div>
    );
  }
);
