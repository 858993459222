import { FC, useState } from "react";
import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalA } from "./ModalA";
import { ButtonA } from "./ButtonA";

const deleteBtnStyle = {
  backgroundColor: "#fff",
  minWidth: "15px",

  ":hover": {
    backgroundColor: "#fff",
  },
};

const removeIconStyle = {
  color: "#000000",
  fontSize: "20px",
};

const cancelBtnStyle = {
  backgroundColor: "#fff",
  color: "#172952",
  width: "100px",
  fontWeight: 600,
  border: "1.5px solid",
  borderRadius: "50px",
  textTransform: "capitalize",
};

type DeleteButtonProps = {
  id: string;
  title: string;
  info: string;
  onHandleDelete: any;
  customContent?: string;
};

export const DeleteButton: FC<DeleteButtonProps> = ({
  id,
  title,
  info,
  onHandleDelete,
  customContent,
}) => {
  const [isShowBox, setIsShowBox] = useState(false);

  const handleCloseModal = () => {
    setIsShowBox(false);
  };

  return (
    <>
      <Button
        sx={deleteBtnStyle}
        onClick={(event) => {
          event.stopPropagation();
          setIsShowBox(true);
        }}
      >
        <DeleteIcon sx={removeIconStyle}></DeleteIcon>
      </Button>

      {isShowBox ? (
        <ModalA
          title={title}
          isOpen={isShowBox}
          onCloseModal={handleCloseModal}
        >
          <Box component="section" sx={{ p: 2 }}>
            <p className="text-center">
              {!!customContent ? (
                customContent
              ) : (
                <>
                  Are you sure you want to delete{" "}
                  <span className="text-indigo-950 font-bold">{info}</span>?
                </>
              )}
            </p>
            <div className="flex flex-row mt-5 justify-center gap-3">
              <ButtonA
                name="Cancel"
                customStyle={cancelBtnStyle}
                onHandleClick={handleCloseModal}
              />
              <ButtonA name="Delete" onHandleClick={onHandleDelete} />
            </div>
          </Box>
        </ModalA>
      ) : null}
    </>
  );
};
