import { useState } from "react";
import { useMutation } from "react-query";
import { fetchGetAvailableTasks } from "../miscellaneous/fetchGetAvailableTasks";

export const useGetAvailableTask = () => {
  const [availableTasks, setAvailableTask] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const mutationQueryAvailableTask = useMutation(fetchGetAvailableTasks, {
    onSuccess: (response) => {
      if (response && response.status === 200) {
        setAvailableTask(response.data.taskTemplateDtos);
        setTotalRecords(response.data.totalRecords);
      }
    },
    onError: () => {},
  });

  return {
    availableTasks,
    totalRecords,
    setAvailableTask,
    mutationQueryAvailableTask,
  };
};
