import "./index.css";
import React from "react";
import axios from "axios";
import ReactDOM from "react-dom/client";
import App from "./app/components/App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { userManager } from "./getOidc";
import { QueryClient, QueryClientProvider } from "react-query";
import { jwtDecode } from "jwt-decode";
import {
  deleteCookie,
  parseCookie,
} from "./common/miscellaneous/handleCookies";
import { checkClearCache } from "./app/miscellaneous/checkClearCache";

axios.defaults.baseURL = process.env.REACT_APP_USER_API_URL;

export const queryClient = new QueryClient();

// Check build version, it not exists info then reload page.
// checkClearCache();

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = parseCookie(document.cookie)["loginToken"];
  config.headers.Authorization = `Bearer ${JSON.parse(token).access_token}`;

  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      // Remove all data in storage
      deleteCookie("loginToken");
      localStorage.removeItem("rolesJson");
      localStorage.removeItem("companyId");
      localStorage.removeItem("jobInfo");

      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

// For fix issue can't navigate to home page when token is out.
window.addEventListener("click", () => {
  const currentPath = window.location.pathname;
  if (!parseCookie(document.cookie)["loginToken"] && currentPath !== "/") {
    window.location.pathname = "/";
  }
});

function getCode() {
  const queryString = window.location.search.replace("?", "");
  const queryStringSplits = queryString.split("&");
  let code;
  queryStringSplits.forEach(function (split) {
    const entry = split.split("=");
    if (entry[0] === "code") {
      code = entry[1];
    }
  });

  return code;
}

if (!!getCode()) {
  userManager.processSigninResponse().then((data) => {
    if (data && data.access_token) {
      const isLocalHost = window.location && window.location.host.includes("localhost");
      const domainToken = isLocalHost ? "localhost" : `.etptracker.com`;

      document.cookie = `loginToken=${JSON.stringify(data)}; expires=${new Date(
        new Date().getTime() + (data.expires_in ?? 0) * 1000
      )}; domain=${domainToken}; path=/`;
      const accessToken = jwtDecode(data.access_token);

      localStorage.setItem(
        "companyId",
        accessToken && (accessToken as any).CompanyId
      );
      localStorage.setItem(
        "jobInfo",
        accessToken && (accessToken as any).CompanyJobs
      );
      localStorage.setItem(
        "rolesJson",
        accessToken && (accessToken as any).rolesJson
      );
    }

    const currentPath = window.location.pathname.split("?")[0];
    // Remove a code param on the URL.
    window.history.replaceState(null, "", currentPath);
    window.location.href = window.location.href;
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
