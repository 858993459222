import { FC } from "react";
import { TableFooter, TablePagination, TableRow } from "@mui/material";
import { TablePaginationActions } from "./TablePaginationActions";

type TableFooterType = {
  data: any;
  rowsPerPage: number;
  page: number;
  totalRecords?: number;
  onHandleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void;
  onHandleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const TableFooterCommon: FC<TableFooterType> = ({
  data,
  rowsPerPage,
  page,
  totalRecords,
  onHandleChangePage,
  onHandleChangeRowsPerPage,
}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[25, {label: "All", value: -1}]}
          colSpan={7}
          count={totalRecords ?? data.length}
          rowsPerPage={rowsPerPage}
          page={!data.length || data.length <= 0 ? 0 : page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={onHandleChangePage}
          onRowsPerPageChange={onHandleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  );
};
