import { FC, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTaskQuestionsValue } from "../hooks/useTaskQuestionsValue";
import dayjs from "dayjs";
import _ from "lodash";
import { useCreateTaskSubmit } from "../hooks/useCreateTaskSubmit";
import { SectionTitle } from "../../common/components/SectionTitle";
import { ButtonCancelA } from "../../common/components/ButtonCancelA";
import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { ButtonSubmitA } from "../../common/components/ButtonSubmitA";
import { Asterisk } from "../../common/components/Asterisk";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ButtonA } from "../../common/components/ButtonA";
import { QuestionsModule } from "./QuestionsModule";
import { useUpdateTaskSubmit } from "../hooks/useUpdateTaskSubmit";
import { useSetTaskQuestionsState } from "../hooks/useSetTaskQuestionsState";
import { formatPayloadUpdateTask } from "../miscellaneous/formatPayloadUpdateTask";
import { textAreaStyle } from "../../tasks-list-management/styles/TaskTemplateFormStyle";
import { OutcomeTooltip } from "./OutcomeTooltip";
import { useOutcomeCommentValue } from "../hooks/useOutcomeCommentValue";
import { useSetOutcomeComment } from "../hooks/useSetOutcomeComment";

const textInputDisableStyle = {
  "& .MuiInputBase-input": {
    height: "5px",
    width: "100%",
  },

  "& .MuiInputBase-input.Mui-disabled": {
    backgroundColor: "#9f9f9f",
  },
};

const selectDisableStyle = {
  height: "38px",
  backgroundColor: "#9f9f9f",
};

const dateTimePickerStyle = {
  width: "70%",
  "& .MuiInputBase-input": {
    height: "5px",
  },
};

export const UpdateTaskSubmission: FC = () => {
  const methods = useForm({});
  const navigate = useNavigate();
  const questionsValue = useTaskQuestionsValue();
  const setQuestions = useSetTaskQuestionsState();

  const { taskId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = methods;

  const [startedDate, setStartedDate] = useState<dayjs.Dayjs | null>(null);
  const [endedDate, setEndedDate] = useState<dayjs.Dayjs | null>(null);
  const [startSubmission, setStartSubmission] = useState<string>("");
  const [endSubmission, setEndSubmission] = useState<string>("");

  const [isShowToastSuccess, setIsShowToastSuccess] = useState(false);

  const [outcome, setOutcome] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const setOutcomeComment = useSetOutcomeComment();
  const outcomeComment = useOutcomeCommentValue();

  const handleBackViewTasks = () => {
    navigate("/task-submission");
  };

  const handleSubmitTask = (data: any) => {
    const formData = {
      ...data,
      starDate: !!startedDate ? startedDate?.format() : taskDetail.startTime,
      endDate: !!endedDate ? endedDate?.format() : taskDetail.endTime,
      startSubmission: !!startSubmission
        ? startSubmission
        : taskDetail.startSubmission,
      endSubmission: !!endSubmission ? endSubmission : taskDetail.endSubmission,
      jobTitleId: !!taskDetail ? taskDetail.jobTitleId : "",
      systemSlowSeconds: dayjs().format(),
    };

    const outcomeValue = _.find(questionsValue ?? [], (item) => {
      return item.question.toLowerCase() === "outcome";
    });

    const payload = formatPayloadUpdateTask(formData, questionsValue);
    const payloadWithId = {
      ...payload,
      id: taskId,
      taskSubmissionTime: dayjs().format(),
      outcome: !!outcomeValue ? outcomeValue.value : "",
      outcomeComment:
        !!outcomeValue && outcomeValue.value === "other" ? outcomeComment : "",
    };

    mutateUpdateTaskSubmission.mutate(payloadWithId);
  };

  const {
    taskTemplate,
    setTaskQuestions,
    mutationGetTaskName,
    mutateGetQuestionsByTask,
  } = useCreateTaskSubmit(null as any);

  const {
    taskDetail,
    setTaskDetail,
    mutateGetTaskDetail,
    mutateUpdateTaskSubmission,
  } = useUpdateTaskSubmit(setIsShowToastSuccess);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    mutationGetTaskName.mutate(companyId as string);
    mutateGetTaskDetail.mutate(taskId as string);

    // Reset question list when begin.
    setTaskQuestions([]);
  }, []);

  useEffect(() => {
    if (taskDetail && !!taskDetail.taskTemplateId) {
      setQuestions(taskDetail.taskSubmissionDetailDtos ?? []);
    }

    if (taskDetail) {
      setOutcome(taskDetail.outcome);
      setComments(taskDetail.outcomeComment);
    }
  }, [taskDetail]);

  const handleChangeStartDateTime = () => {
    setStartSubmission(dayjs().format());
    setStartedDate(dayjs());

    setValue("startDate", dayjs());
    clearErrors("startDate");
  };

  const handleChangeEndDateTime = () => {
    setEndSubmission(dayjs().format());
    setEndedDate(dayjs());

    setValue("endDate", dayjs());
    clearErrors("endDate");
  };

  const handleCloseToast = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsShowToastSuccess(false);
  };

  return !!taskDetail ? (
    <div className="mt-9">
      <div className="flex flex-row justify-between">
        <SectionTitle name="Task Detail" />
        <ButtonCancelA
          name="View History"
          onHandleClick={handleBackViewTasks}
        />
      </div>

      <Box sx={{ minWidth: 120, marginTop: "60px", marginBottom: "200px" }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSubmitTask)}>
            <div className={"flex flex-row justify-between items-center"}>
              <span className="text-sub-title">Task Summary Information</span>
              <div className="flex flex-row gap-4">
                <ButtonSubmitA name="Submit" />
              </div>
            </div>

            <div className="grid grid-flow-col auto-cols-fr gap-8 pl-20 pr-20">
              <FormGroup>
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Task ID <Asterisk />
                  </label>
                  <TextField
                    defaultValue={taskDetail.id ?? ""}
                    disabled
                    sx={textInputDisableStyle}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    <span>Start Time</span> <Asterisk />
                  </label>

                  <div className="flex flex-row justify-between">
                    <Controller
                      control={control}
                      name="startDate"
                      defaultValue={dayjs(taskDetail.startTime) ?? null}
                      render={({ field: { onChange } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            disableOpenPicker
                            onChange={(newValue: any) => {
                              onChange(newValue);
                              setStartedDate(newValue);
                            }}
                            value={
                              startedDate ?? dayjs(taskDetail.startTime) ?? null
                            }
                            sx={dateTimePickerStyle}
                          />
                        </LocalizationProvider>
                      )}
                    ></Controller>
                    <ButtonA
                      name="Set value"
                      onHandleClick={handleChangeStartDateTime}
                    />
                  </div>
                </FormControl>
              </FormGroup>

              <FormGroup>
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Select Role <Asterisk />
                  </label>
                  <Select
                    id="user-job-title"
                    disabled
                    value={taskDetail.jobTitle.id ?? ""}
                    style={selectDisableStyle}
                  >
                    <MenuItem value={taskDetail.jobTitle.id ?? ""}>
                      {taskDetail.jobTitle.title ?? ""}
                    </MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
            </div>

            <div className={"mt-10"}>
              <span className="text-sub-title">Task Detail</span>

              <div className="pl-20 pr-20">
                <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Select Task <Asterisk />
                  </label>
                  <Controller
                    control={control}
                    name="taskName"
                    defaultValue={taskDetail.taskTemplateId}
                    rules={{ required: "Task Name is required" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        id="taskName"
                        value={value ?? ""}
                        onChange={(e) => {
                          setValue("taskName", e.target.value);
                          clearErrors("taskName");

                          mutateGetQuestionsByTask.mutate(e.target.value);
                        }}
                        disabled={true}
                        style={{ height: "38px", backgroundColor: "#9f9f9f" }}
                      >
                        {(taskTemplate ?? []).map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.taskName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("taskName").error?.message}
                  </FormHelperText>
                </FormControl>

                {/* <FormControl fullWidth sx={{ marginTop: "23px" }}>
                  <label className="font-bold">
                    Outcome <Asterisk /> <OutcomeTooltip />
                  </label>
                  <Controller
                    control={control}
                    name="outcome"
                    defaultValue={taskDetail.outcome ?? ""}
                    rules={{ required: "Outcome is required" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        id="outcome"
                        value={value ?? ""}
                        onChange={(e) => {
                          setValue("outcome", e.target.value);
                          setOutcome(e.target.value);
                          clearErrors("outcome");
                        }}
                        style={{ height: "38px" }}
                      >
                        <MenuItem key={"suspend"} value={"suspend"}>
                          Suspend
                        </MenuItem>
                        <MenuItem
                          key={"suspend / interruption"}
                          value={"suspend / interruption"}
                        >
                          Suspend / Interruption
                        </MenuItem>
                        <MenuItem key={"completed"} value={"completed"}>
                          Completed
                        </MenuItem>
                        <MenuItem
                          key={"completed / no action taken"}
                          value={"completed / no action taken"}
                        >
                          Completed / No Action Taken
                        </MenuItem>
                        <MenuItem key={"delete"} value={"delete"}>
                          Delete
                        </MenuItem>
                        <MenuItem
                          key={"external delay"}
                          value={"external delay"}
                        >
                          External Delay
                        </MenuItem>
                        <MenuItem
                          key={"external delay - nigo"}
                          value={"external delay - nigo"}
                        >
                          External Delay - NIGO
                        </MenuItem>
                        <MenuItem
                          key={"internal delay"}
                          value={"internal delay"}
                        >
                          Internal Delay
                        </MenuItem>
                        <MenuItem
                          key={"review only - no action taken"}
                          value={"review only - no action taken"}
                        >
                          Review Only - No Action Taken
                        </MenuItem>
                        <MenuItem key={"other"} value={"other"}>
                          Other
                        </MenuItem>
                      </Select>
                    )}
                  ></Controller>
                  <FormHelperText sx={{ color: "red" }}>
                    {control.getFieldState("outcome").error?.message}
                  </FormHelperText>
                </FormControl> */}

                {/* {outcome === "other" ? (
                  <FormControl fullWidth sx={{ marginTop: "23px" }}>
                    <label className="font-bold">Comments</label>
                    <TextareaAutosize
                      placeholder=""
                      defaultValue={taskDetail.outcomeComment ?? ""}
                      value={comments ?? ""}
                      minRows={3}
                      onChange={(event) => {
                        setComments(event.target.value);
                      }}
                      style={textAreaStyle}
                    />
                  </FormControl>
                ) : null} */}
                <QuestionsModule />

                <FormControl sx={{ marginTop: "23px", width: "48%" }}>
                  <label className="font-bold">
                    <span>End Time</span> <Asterisk />
                  </label>
                  <div className="flex flex-row justify-between">
                    <Controller
                      control={control}
                      name="endDate"
                      defaultValue={dayjs(taskDetail.endTime) ?? null}
                      render={({ field: { onChange } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            disableOpenPicker
                            onChange={(newValue: any) => {
                              onChange(newValue);
                              setEndedDate(newValue);
                            }}
                            value={
                              endedDate ?? dayjs(taskDetail.endTime) ?? null
                            }
                            sx={dateTimePickerStyle}
                          />
                        </LocalizationProvider>
                      )}
                    ></Controller>
                    <ButtonA
                      name="Set value"
                      onHandleClick={handleChangeEndDateTime}
                    />
                  </div>
                </FormControl>
              </div>
            </div>

            <div className="flex flex-row justify-center mt-10 mp-10">
              <ButtonSubmitA name="Submit" />
            </div>
          </form>
        </FormProvider>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isShowToastSuccess}
        autoHideDuration={5000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Update task detail success
        </Alert>
      </Snackbar>
    </div>
  ) : null;
};
